
export const shopItem_easypos = {
  inputFields: [

    /**
     * @POS메뉴에서_가져온_오리지널_속성_편집불가
     */
    { name: "groupCode", posName: "TOUCH_CLASS_CODE" },                  // 그룹코드
    // { name: "groupName", posName: "TOUCH_CLASS_NAME" },               // touchKey 에 항목이 없음
    { name: "itemCode", posName: "ITEM_CODE" },
    { name: "itemName", posName: "ITEM_NAME" },
    { name: "price", posName: "ITEM_PRICE" },                           // Number

    { name: "SUB_MENU_TYPE", posName: "SUB_MENU_TYPE" },
    { name: "SERVICE_FLAG", posName: "SERVICE_FLAG" },
    { name: "ITEM_TYPE", posName: "ITEM_TYPE" },
    { name: "KIOSK_SUB_MENU_TYPE", posName: "KIOSK_SUB_MENU_TYPE" },
    // { name: "oriImage", posName: "ITEM_IMG_URL" },

    /**
     * @ONL메뉴에서_편집_가능한_속성들
     */
    { name: "itemName2", defaultValue: { posName: "ITEM_NAME" } },
    { name: "description", defaultValue: { posName: "ITEM_DESCRIPTION" } },

    { name: "sortOrder", defaultValue: 0 },
    { name: "image", defaultValue: "" },
    { name: "removed", defaultValue: false },                         // ONL 메뉴에서 제거하고 싶을 때
    { name: "isSoldout", defaultValue: false },
    { name: "isNew", defaultValue: false },
    { name: "isHot", defaultValue: false },
    { name: "isBest", defaultValue: false },

    // 
    // 언어 저장, 기본값 undefined
    // 
    // { name: "nameLang" },           // {KR, EN, CN, JP}
    // { name: "descriptionLang" },    // {KR, EN, CN, JP}
  ],
};
