import React, { useEffect, useState } from "react";
import { useStyles, FormInput, ModalForm, FormGroup, FormBox } from "../../../components";
import { updateItem } from "../../../lib-pos";
import { shopItemOptionUpdate } from "../../../lib/ec2-api-lib";
import { sortObjArray2 } from "../../../lib/utils";

export default function SoldoutModal(props) {
  const {
    visible, setVisible,
    shopInfo,
    itemList,
    onload,
  } = props;
  const { gStyle } = useStyles();

  const modalTitle = "품절상품 관리";
  const [isLoading, setIsLoading] = useState(false);

  const [soldoutList, setSoldoutList] = useState([])

  /** @EASY_POS itemOptions2.orderList */
  const [easyposOrderList, setEasyposOrderList] = useState([])

  useEffect(() => {
    const sItems = itemList.filter(item => item.isSoldout)
    const _sItems = sItems.map(sitem => {
      return {
        id: sitem.id,
        itemCode: sitem.itemCode,
        itemName: sitem.itemName,
        itemName2: sitem.itemName2,
        isSoldout: sitem.isSoldout,
      }
    })
    setSoldoutList(_sItems)

    if (shopInfo.posName === "EASY_POS") {
      if (props.itemOptions3?.orderList) {
        const sOrderList = props.itemOptions3?.orderList?.filter(ol => ol?.isSoldout)
        const sorted = sortObjArray2({ arr: sOrderList, key: "ITEM_NAME" })
        setEasyposOrderList(sorted)
      }
    }

  }, [itemList, props.itemOptions3])

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      let errMsg = ""
      await Promise.all(soldoutList.map(async item => {
        if (item.isSoldout) return null
        let res = await updateItem({
          shopInfo,
          id: item.id,
          body: { isSoldout: item.isSoldout }
        })
        if (res.err) errMsg += res.err.message + " "
      }))
      if (errMsg !== "") throw Error(errMsg)

      if (shopInfo.posName === "EASY_POS" && easyposOrderList.length > 0) {
        const newOrderList = props.itemOptions3.orderList.map(ol => {
          const changedOrders = easyposOrderList.filter(o => o.ITEM_CODE === ol.ITEM_CODE)
          if (changedOrders.length > 0) {
            return { ...ol, isSoldout: changedOrders[0].isSoldout }
          } else {
            return ol
          }
        })

        const params = {
          shopId: shopInfo.shopId,
          optionName: shopInfo.posName,
          body: { orderList: JSON.stringify(newOrderList) }
        };

        let res = await shopItemOptionUpdate(params);
        if (res.err) throw new Error(res.err.message);
      }

      await onload();
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const onChange = (e) => {
    const itemCode = e.target.name;
    setSoldoutList(preList => {
      const index = preList.findIndex(_item => _item.itemCode === itemCode)
      const newItems = [...preList]
      newItems[index].isSoldout = !newItems[index].isSoldout
      return newItems
    })
  }

  const onChangeEasyposOrderItem = (e) => {
    const ITEM_CODE = e.target.name

    const _newOrderList = [...easyposOrderList]
    const newOrderList = _newOrderList.map(o => {
      if (o.ITEM_CODE === ITEM_CODE) return { ...o, isSoldout: !o.isSoldout }
      else return o
    })
    setEasyposOrderList(newOrderList)
  }

  const styles = {
    text: { ...gStyle.text, textAlign: "center" },
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={modalTitle}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <FormGroup>
        {soldoutList.length > 0 &&
          <p style={styles.text}>체크해제 후 확인 클릭을 누르면 주문이 재개 됩니다.</p>}
        <FormBox>
          {
            soldoutList.length > 0 &&
            soldoutList.map((item, i) => {
              return (
                <FormInput
                  key={i}
                  label={item.itemName}
                  name={item.itemCode}
                  checked={item.isSoldout}
                  onChange={onChange}
                  inputType="checkbox"
                />
              )
            })
          }
          {soldoutList.length === 0 &&
            <p style={{ ...styles.text, marginBottom: "15px" }}>품절상품이 없습니다.</p>}
        </FormBox>
      </FormGroup>

      {(shopInfo.posName === "EASY_POS" && easyposOrderList.length > 0) &&
        <FormGroup>
          <p style={{ ...styles.text, textAlign: "left" }}>이지포스 옵션 아이템 리스트</p>
          <FormBox>
            {
              easyposOrderList.map((item, i) => {
                return (
                  <FormInput
                    key={i}
                    label={item.ITEM_NAME}
                    name={item.ITEM_CODE}
                    checked={item.isSoldout}
                    onChange={onChangeEasyposOrderItem}
                    inputType="checkbox"
                  />
                )
              })
            }
          </FormBox>
        </FormGroup>
      }
    </ModalForm>
  )
}
