import { isJson } from "../lib/utils";

const pos = {
  "UNIONPOS": { parseItemOptions: parseItemOptionsUnionpos, },
  "OKPOS": { parseItemOptions: parseItemOptionsOkpos },
  "DUMMY_POS": { parseItemOptions: parseItemOptionsDummypos },
  "OASIS_POS": { parseItemOptions: parseItemOptionsOasispos },
  "EASY_POS": { parseItemOptions: parseItemOptionsEasypos },
}

const posList = Object.keys(pos)

export function parseItemOptions({ shopInfo, itemOptions, selectedLanguage }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  return pos[shopInfo.posName].parseItemOptions({ shopInfo, itemOptions, selectedLanguage })
}

function parseItemOptionsUnionpos({ itemOptions, selectedLanguage }) {

  const menuSettings = isJson(itemOptions.menuSettings) ? JSON.parse(itemOptions.menuSettings) : {}
  const GrpList = isJson(itemOptions.GrpList) ? JSON.parse(itemOptions.GrpList) : []
  // const newGrpList = setLanguage({ GrpList, selectedLanguage, usePosMenuFirst: menuSettings.usePosMenuFirst })

  return {
    ...itemOptions,
    GrpList,
    MidGrpList: isJson(itemOptions.MidGrpList) ? JSON.parse(itemOptions.MidGrpList) : [],
    SetMenuList: isJson(itemOptions.SetMenuList) ? JSON.parse(itemOptions.SetMenuList) : [],
    ChoiceMenuList: isJson(itemOptions.ChoiceMenuList) ? JSON.parse(itemOptions.ChoiceMenuList) : [],
    TableList: isJson(itemOptions.TableList) ? JSON.parse(itemOptions.TableList) : [],
    menuSettings,
    tableCallList: isJson(itemOptions.tableCallList) ? JSON.parse(itemOptions.tableCallList) : [],
  }
}

function parseItemOptionsOkpos({ itemOptions, selectedLanguage }) {
  const names = [
    "TuClsList",
    "GrpList", "MidGrpList", "SmGrpList",
    "SdaCdList", "SdaClsList",
    "SdsCdList", "SdsClsList", "SdsGrList",
    "TableList", "menuSettings", "tableCallList",
    "optionGroupList", "optionClassList", "optionItemList",
  ]
  const itemOptions2 = { ...itemOptions }
  names.map(name => {
    itemOptions2[name] = isJson(itemOptions[name]) ? JSON.parse(itemOptions[name])
      : (name === "menuSettings") ? {} : []
  })
  return itemOptions2
}

function parseItemOptionsDummypos({ itemOptions }) {
  const names = [
    "groupList",
    "optionGroupList", "optionClassList", "optionItemList",
    "tableList", "tableGroupList",
    "menuSettings", "tableCallList",
  ]
  const itemOptions2 = { ...itemOptions }
  names.map(name => {
    itemOptions2[name] = isJson(itemOptions[name]) ? JSON.parse(itemOptions[name])
      : (name === "menuSettings") ? {} : []
  })
  return itemOptions2
}

function parseItemOptionsOasispos({ itemOptions }) {
  const names = [
    "groupList",
    "setMenuList",
    "tableList", "menuSettings", "tableCallList",
  ]
  const itemOptions2 = { ...itemOptions }
  names.map(name => {
    itemOptions2[name] = isJson(itemOptions[name]) ? JSON.parse(itemOptions[name])
      : (name === "menuSettings") ? {} : []
  })

  const groupList = itemOptions2.groupList.map(g => {
    return {
      ...g,
      name: g.groupName,
      GrpCode: g.groupCode,
      GrpName: g.groupName,
    }
  })

  itemOptions2.groupList = groupList

  return itemOptions2
}

function parseItemOptionsEasypos({ itemOptions }) {
  const names = [
    "tableGroupList", "tableList",
    "touchClassList",   // groupList 에 해당
    "menuSettings",
    "tableCallList",
    "subItemList", "orderClassList", "orderList", "itemOrderClassList"
  ]
  const itemOptions2 = { ...itemOptions }
  names.map(name => {
    itemOptions2[name] = isJson(itemOptions[name])
      ? JSON.parse(itemOptions[name])
      : (name === "menuSettings") ? {} : []
  })

  itemOptions2.groupList = itemOptions2.touchClassList
  return itemOptions2
}

const setLanguage = ({ GrpList, selectedLanguage, usePosMenuFirst }) => {

  const newGrpList = GrpList.map(group => {
    // const nameLang = isJson(group.nameLang) ? JSON.parse(group.nameLang) : {}
    // const posNameLang = isJson(group.posNameLang) ? JSON.parse(group.posNameLang) : {}
    const nameLang = group.nameLang
    const posNameLang = group.posNameLang

    let name = group.name
    if (posNameLang) name = selectedLanguage === "KR" ? group.name : posNameLang[selectedLanguage] || group.name

    let displayName = group.displayNamee
    if (nameLang) {
      displayName = selectedLanguage === "KR"
        ? group.displayName
        : nameLang[selectedLanguage] || group.displayName
    }

    return { ...group, name, displayName, GrpName: name }
  })

  // const grps = newGrpList.filter(grp => grp.GrpCode === "00070")
  // console.log(grps[0])

  return newGrpList
}
