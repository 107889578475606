import React, { useEffect, useState } from "react";
import {
  CardContainer, useStyles,
  ModalForm, FormGroup,
  FormInput
} from "../../../components";
import { CustomTable2 } from "../../../components/Tables2";
import { shopItemOptionUpdate } from "../../../lib/ec2-api-lib";
import { sortObjArray2 } from "../../../lib/utils";
import { debug } from "../../../settings";
import { useInputFields } from "../../../lib/hooksLib";

const tHeaders = {
  items: [
    { name: "ORDER_CLASS_CODE", displayName: "Order Class Code", sorting: true },
    { name: "ITEM_CODE", displayName: "아이템코드", selectKey: true, sorting: true },
    { name: "ITEM_NAME", displayName: "아이템이름", sorting: true },
    { name: "ITEM_PRICE", displayName: "가격" },
    { name: "isSoldout", displayName: "Soldout" },
  ],
}


export default function EasyPosOptionMenus(props) {
  const { itemOptions3, onload, setIsLoading } = props

  // const { gStyle, colors } = useStyles();
  const title = 'EASYPOS Soldout 세팅';

  const [orderList, setOrderList] = useState([])

  const [visibleItemEdit, setVisibleItemEdit] = useState(false)
  const [orderItem, setOrderItem] = useState({})

  useEffect(() => {
    start()
  }, [itemOptions3])

  const start = async () => {
    try {
      setIsLoading(true)

      let optionItemList = itemOptions3.orderList || []
      const sorted = sortObjArray2({ arr: optionItemList, key: "ITEM_NAME" })
      setOrderList(sorted)

    } catch (e) {
      if (debug) console.log(e)
      alert(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>

      <CardContainer
        title={title}
        onClickBack={() => props.history.goBack()}
      >
        <CustomTable2
          tableHeader={tHeaders.items}
          rowItems={orderList}
          setRowItems={setOrderList}
          onClickItem={(item) => {
            setOrderItem(item)
            setVisibleItemEdit(true)
          }}
        />
      </CardContainer>

      <>
        {visibleItemEdit &&
          <OrderItemModal
            {...props}
            item={orderItem}
            visible={visibleItemEdit}
            setVisible={setVisibleItemEdit}
          />
        }
      </>
    </div>
  )
}


function OrderItemModal(props) {
  const orderItem = props.item
  const modalTitle = `"${orderItem.ITEM_NAME}" 편집`

  const [fields, setFields] = useInputFields({
    isSoldout: (orderItem.isSoldout === undefined)
      ? false
      : orderItem.isSoldout
  })

  const onSubmit = async () => {
    try {
      props.setIsLoading(true)

      const orderList = [...props.itemOptions3.orderList]

      const newOrderList = orderList.map(o=>{
        if(o.ITEM_CODE===orderItem.ITEM_CODE) {
          return {
            ...o,
            isSoldout: fields.isSoldout
          }
        } else {
          return o
        }
      })

      const params = {
        shopId: props.shopInfo.shopId,
        optionName: props.shopInfo.posName,
        body: { orderList: JSON.stringify(newOrderList) }
      };

      let res = await shopItemOptionUpdate(params);
      if (res.err) throw new Error(res.err.message);

      await props.onload()
    } catch (e) {
      alert(e.message)
    } finally {
      props.setIsLoading(false)
      props.setVisible(false)
    }
  }

  return (
    <ModalForm
      visible={props.visible}
      setIsVisible={props.setVisible}
      title={modalTitle}
      isLoading={props.isLoading}
      size="md"
      backdrop="static"
      onSubmit={onSubmit}
    >
      <FormGroup>
        <FormInput
          label="Solout 여부"
          name="isSoldout"
          checked={fields.isSoldout}
          onChange={setFields}
          inputType="checkbox"
        />
      </FormGroup>
    </ModalForm>
  )
}
