import React, { useEffect, useState } from "react";
import { useStyles, ModalForm, FormInput, Button, UnderlineButton } from "../../../components";
// import { CustomTable2 } from "../../../components/Tables2";
import { debug } from "../../../settings";
import { shopItemOptionUpdate } from "../../../lib/ec2-api-lib";
import { useInputFields } from "../../../lib/hooksLib";
import { hasSameName } from "../../../lib/utils";

const str = {
  noItemsSelected: "선택된 항목이 없습니다.",
  inValidMaxQty: "제한 수량이 1이 아니면 비워 두세요!",
  notSuportedPos: "POS NOT SUPPORTED"
}

export function DposTableModal(props) {
  const modalTitle = (props.mode === "table" ? "테이블" : "테이블 그룹") + (props.isEdit ? " 편집 " : " 추가");

  const modalTitleDel = (props.editItem?.tableGroupName || props.editItem?.tableName) + " 삭제 확인"

  const [isLoading, setIsLoading] = useState(false);
  const [delMode, setDelMode] = useState(false)

  const onSubmitDel = async () => {
    try {
      setIsLoading(true);

      // tableGroup
      if (props.mode === "tableGroup") {

        if (_checkIfGroupHasTables(props.editItem.tableGroupCode)) {
          throw Error('해당 그룹에 테이블이 존재합니다. 테이블을 모두 삭제 후 그룹을 삭제해야 합니다.')
        }

        let newTableGroups = []
        props.itemOptions3.tableGroupList.map(g => {
          if (g.tableGroupCode !== props.editItem.tableGroupCode) {
            newTableGroups.push(g)
          }
          return null
        })

        const params = {
          shopId: props.shopInfo.shopId,
          optionName: props.shopInfo.posName,
          body: {
            tableGroupList: JSON.stringify(newTableGroups),
          }
        }

        const res = await shopItemOptionUpdate(params)
        if (res.err) throw Error(res.err.message)
      }

      // table
      else {
        let newTables = []
        props.itemOptions3.tableList.map(t => {
          if (t.tableCode !== props.editItem.tableCode) {
            newTables.push(t)
          }
          return null
        })

        const params = {
          shopId: props.shopInfo.shopId,
          optionName: props.shopInfo.posName,
          body: {
            tableList: JSON.stringify(newTables),
          }
        }

        const res = await shopItemOptionUpdate(params)
        if (res.err) throw Error(res.err.message)
      }

      props.onload()
    } catch (e) {
      if (debug) console.log(e)
      alert(e.message);
    } finally {
      setIsLoading(false);
      props.setVisible(false);
    }
  }

  const _checkIfGroupHasTables = (tgCode) => {
    const tables = props.itemOptions3.tableList.filter(t => t.tableGroupCode === tgCode)
    if (tables.length > 0) return true
    return false
  }

  const onSubmit = async (fields) => {
    try {
      setIsLoading(true);

      let res = {}
      if (props.mode === "tableGroup") {
        res = await _onSubmitTableGroup(fields)
      } else {
        res = await _onSubmitTable(fields)
      }

      if (res.err) throw Error(res.err.message)
      props.onload()
    } catch (e) {
      if (debug) console.log(e)
      alert(e.message);
    } finally {
      setIsLoading(false);
      props.setVisible(false);
    }
  }

  const _onSubmitTableGroup = async (fields) => {
    try {

      if(fields.tableGroupCode==="" || fields.tableGroupName==="") {
        throw Error("코드나 이름이 비어 있습니다.")
      }

      const tableGroupList = props.itemOptions3.tableGroupList

      let newTableGroups = []

      // 편집
      if (props.isEdit) {

        const restArr = tableGroupList.filter(g => g.tableGroupCode !== fields.tableGroupCode)
        if (hasSameName({ itemObj: fields, arr: restArr, key: "tableGroupName" })) {
          throw Error("동일한 이름이 이미 존재합니다.")
        }

        newTableGroups = tableGroupList.map(g => {
          if (g.tableGroupCode === fields.tableGroupCode) {
            return fields
          } else {
            return g
          }
        })
      }

      // 추가
      else {
        newTableGroups = [...tableGroupList]

        if (hasSameName({ itemObj: fields, arr: newTableGroups, key: "tableGroupCode" })) {
          throw Error("동일한 코드가 이미 존재합니다.")
        }

        if (hasSameName({ itemObj: fields, arr: newTableGroups, key: "tableGroupName" })) {
          throw Error("동일한 이름이 이미 존재합니다.")
        }

        newTableGroups.push(fields)
      }

      const params = {
        shopId: props.shopInfo.shopId,
        optionName: props.shopInfo.posName,
        body: {
          tableGroupList: JSON.stringify(newTableGroups),
        }
      }

      let res = await shopItemOptionUpdate(params)
      if (res.err) throw Error(res.err.message)
      return {}
    } catch (e) {
      return { err: { message: e.message } }
    }
  }

  const _onSubmitTable = async (fields) => {
    try {

      const tableList = props.itemOptions3.tableList
      console.log('='.repeat(50), props.mode)

      console.log('tableList', tableList)
      console.log(fields)

      let newTables = []
      // 편집
      if (props.isEdit) {

        const restArr = tableList.filter(g => g.tableCode !== fields.tableCode)
        if (hasSameName({ itemObj: fields, arr: restArr, key: "tableName" })) {
          throw Error("동일한 이름이 이미 존재합니다.")
        }

        newTables = tableList.map(g => {
          if (g.tableCode === fields.tableCode) {
            return fields
          } else {
            return g
          }
        })
      }

      // 추가
      else {
        newTables = [...tableList]

        if (hasSameName({ itemObj: fields, arr: newTables, key: "tableCode" })) {
          throw Error("동일한 코드가 이미 존재합니다.")
        }

        if (hasSameName({ itemObj: fields, arr: newTables, key: "tableName" })) {
          throw Error("동일한 이름이 이미 존재합니다.")
        }

        newTables.push(fields)
      }

      console.table(newTables)

      const params = {
        shopId: props.shopInfo.shopId,
        optionName: props.shopInfo.posName,
        body: {
          tableList: JSON.stringify(newTables),
        }
      }

      let res = await shopItemOptionUpdate(params)
      if (res.err) throw Error(res.err.message)
      return {}
    } catch (e) {
      return { err: { message: e.message } }
    }
  }

  return (
    <ModalForm
      visible={props.visible}
      setIsVisible={props.setVisible}
      title={delMode ? modalTitleDel : modalTitle}
      isLoading={isLoading}
      size={"md"}
      backdrop="static"
    >
      {delMode &&
        <>
          <br />
          <Button
            title="확인"
            variant="info"
            width="100%"
            onClick={onSubmitDel}
          />
        </>
      }

      {!delMode &&
        <>
          {props.mode === "tableGroup" &&
            <EditTableGroup
              {...props}
              onSubmit={onSubmit}
            />
          }

          {props.mode === "table" &&
            <EditTable
              {...props}
              onSubmit={onSubmit}
            />
          }

          {props.isEdit &&
            <UnderlineButton
              title="삭제"
              // onClick={onSubmitDel}
              onClick={() => setDelMode(true)}
              size="sm"
              style={{ width: "30%" }}
              isLoading={isLoading}
            />
          }
        </>
      }
    </ModalForm>
  )
}


function EditTableGroup(props) {

  const [fields, setFields] = useState({
    tableGroupCode: "",
    tableGroupName: "",
  })

  useEffect(() => {
    if (props.isEdit) {
      setFields({ ...props.editItem })
    }
  }, [props])

  return (
    <div>
      <FormInput
        label="Table Group Code"
        name="tableGroupCode"
        value={fields.tableGroupCode}
        onChange={(e) => {
          setFields(pre => {
            return { ...pre, tableGroupCode: e.target.value }
          })
        }}
      />

      <FormInput
        label="Table Group Name"
        name="tableGroupName"
        value={fields.tableGroupName}
        onChange={(e) => {
          setFields(pre => {
            return { ...pre, tableGroupName: e.target.value }
          })
        }}
      />

      <Button
        variant="info"
        width="100%"
        size="sm"
        title="확인"
        onClick={() => props.onSubmit(fields)}
      />
    </div>
  )
}

function EditTable(props) {

  const tgList = props.itemOptions3.tableGroupList || []
  const tableGroupCodes = tgList.map(tg => tg.tableGroupCode)

  const [fields, setFields] = useState({
    tableGroupCode: tableGroupCodes[0],
    tableNo: "",
    tableCode: "",
    tableName: "",
  })

  useEffect(() => {
    if (props.isEdit) {
      setFields({ ...props.editItem })
    }
  }, [props])

  return (
    <div>
      <FormInput
        label="TableGroupCode"
        name="tableGroupCode"
        value={fields.tableGroupCode}
        inputType="select"
        selectList={tableGroupCodes}
        onChange={(e) => {
          setFields(pre => {
            return { ...pre, tableGroupCode: e.target.value }
          })
        }}
      />

      <FormInput
        label="TableNo (TableCode)"
        name="tableNo"
        value={fields.tableNo}
        onChange={(e) => {
          setFields(pre => {
            return { ...pre, tableNo: e.target.value, tableCode: e.target.value }
          })
        }}
      />

      <FormInput
        label="TableName"
        name="tableName"
        value={fields.tableName}
        onChange={(e) => {
          setFields(pre => {
            return { ...pre, tableName: e.target.value }
          })
        }}
      />

      <Button
        variant="primary"
        width="100%"
        size="sm"
        title="확인"
        onClick={() => props.onSubmit(fields)}
      />
    </div>
  )
}
