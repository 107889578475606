import React, { useEffect, useState } from "react";
import { CardContainer, useStyles, Button, } from "../../../components";
import { CustomTable2 } from "../../../components/Tables2";
import { shopItemOptionUpdate } from "../../../lib/ec2-api-lib";
import { Plus } from "../../../components/Icons";
import { sortObjArray2 } from "../../../lib/utils";
import { debug } from "../../../settings";

import { DposTableModal } from "./DposTableModal";


const tableHeadersDpos = {
  tableGroupList: [
    { name: "tableGroupCode", displayName: "TableGroupCode", selectKey: true },
    { name: "tableGroupName", displayName: "TableGroupName" },
  ],
  tableList: [
    { name: "tableGroupCode", displayName: "TableGroupCode", sorting: true },
    { name: "tableCode", displayName: "TableCode", sorting: true, selectKey: true },
    { name: "tableName", displayName: "TableName", sorting: true },
    { name: "tableNo", displayName: "Table No" },
  ],
  checkObj: { name: "checked", displayName: "선택", type: "checkbox" },
  sorting: { name: "sorting", displayName: "순서", type: "sorting" },
}

const str = {
  noItemFound: "NO ITEM FOUND",
  noItems: "No Items"
}

export default function DposTableList(props) {
  const { shopInfo, itemOptions3, onload } = props

  const { gStyle, colors } = useStyles();
  const title = '테이블 리스트 편집 - ' + shopInfo.posName;

  const [tableGroupList, setTableGroupList] = useState([])
  const [tableList, setTableList] = useState([])

  const [visibleTableGroup, setVisibleTableGroup] = useState(false)
  const [selectedTableGroup, setSelectedTableGroup] = useState({})

  const [visibleTable, setVisibleTable] = useState(false)
  const [selectedTable, setSelectedTable] = useState({})

  const [isEditTable, setIsEditTable] = useState(false)

  useEffect(() => {
    async function start() {

      let tableGroups = itemOptions3.tableGroupList || []

      if (tableGroups.length === 0) {
        await createDefatulTables()
      } else {
        setTableGroupList(tableGroups)
        setTableList(itemOptions3.tableList || [])
      }
    }
    start()
  }, [itemOptions3])

  const createDefatulTables = async () => {
    try {
      const defaultTableGroupList = [{ tableGroupCode: "01", tableGroupName: "Floor 1" }]
      const defaultTableList = []
      for (let i = 0; i < 20; i++) {
        const no = i + 1
        const tableNo = no.toString().padStart(3, "0")
        defaultTableList.push({
          tableGroupCode: "01",
          tableNo,
          tableCode: tableNo,
          tableName: "TABLE " + no,
        })
      }

      const params = {
        shopId: shopInfo.shopId,
        optionName: shopInfo.posName,
        body: {
          tableGroupList: JSON.stringify(defaultTableGroupList),
          tableList: JSON.stringify(defaultTableList),
        }
      }

      let res = await shopItemOptionUpdate(params)

      if (res.err) throw Error(res.err.message)
      onload()
    } catch (e) {
      alert(e.message)
    }
  }

  const styles = {
    subTitleBar: {
      margin: 5,
      display: "flex",
      // justifyContent: "space-between",
      alignItems: "center"
    },
    subTitle: {
      ...gStyle.subtitle
    },
    iconPlus: { ...gStyle.subtitle, color: colors.primary, marginLeft: "20px" },
  }

  return (
    <CardContainer
      title={title}
      onClickBack={() => props.history.goBack()}
    >
      <div style={styles.subTitleBar}>
        <p style={styles.subTitle}>Table Group List</p>
        <Plus
          style={styles.iconPlus}
          onClick={() => {
            setSelectedTableGroup({})
            setIsEditTable(false)
            setVisibleTableGroup(true)
          }}
        />
      </div>
      <CustomTable2
        tableHeader={tableHeadersDpos.tableGroupList}
        rowItems={tableGroupList}
        setRowItems={setTableGroupList}
        onClickItem={(item) => {
          setSelectedTableGroup(item)
          setIsEditTable(true)
          setVisibleTableGroup(true)
        }}
      />
      <hr />

      <div style={styles.subTitleBar}>
        <p style={styles.subTitle}>Table List</p>
        <Plus
          style={styles.iconPlus}
          onClick={() => {
            setSelectedTable({})
            setIsEditTable(false)
            setVisibleTable(true)
          }}
        />
      </div>
      <CustomTable2
        tableHeader={tableHeadersDpos.tableList}
        rowItems={tableList}
        setRowItems={setTableList}
        onClickItem={(item) => {
          setSelectedTable(item)
          setIsEditTable(true)
          setVisibleTable(true)
        }}
      />

      {visibleTableGroup &&
        <DposTableModal
          {...props}
          visible={visibleTableGroup}
          setVisible={setVisibleTableGroup}
          isEdit={isEditTable}
          editItem={selectedTableGroup}
          mode="tableGroup"
        />
      }

      {visibleTable &&
        <DposTableModal
          {...props}
          visible={visibleTable}
          setVisible={setVisibleTable}
          isEdit={isEditTable}
          editItem={selectedTable}
          mode="table"
        />
      }

    </CardContainer>
  )
}
