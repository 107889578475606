import {
  okposItemUpdate, okposItemList,
  okposProxy,
  okposUpdateMenu,
  okposItemOptionUpdate, okposItemOptionGet,
  onlItemOptionGet, onlItemOptionUpdate,
} from "../../lib/ec2-api-lib";
import { isJson, sortObjArray, delay } from "../../lib/utils";
import { getOnlOptionItems } from "../getOnlOptionItems";
import { parseItemOptions } from "../parseItemOptions";
import { debug } from "../../settings";

const delayTime = 500;

/**
 * @deprecated 
 */
export function updateOkposMenu({ shopInfo }) {
  return okposUpdateMenu({
    body: { shopId: shopInfo.shopId, storeCode: shopInfo.storeCode }
  })
}

/** @deprecated */
export async function resetOkposMenu({ shopInfo, itemList, itemOptions, itemOptions2 }) {
  try {

    // 
    // reset GrpList
    //  
    const TuClsList = itemOptions2.TuClsList;
    // const GrpList = itemOptions2.GrpList;
    // const MidGrpList = itemOptions2.MidGrpList;
    // const SmGrpList = itemOptions2.SmGrpList;

    const newTuClsList = TuClsList.map(group => {
      return {
        ...group,
        GrpName: group.TU_CLS_NM,
        name: group.TU_CLS_NM,
        displayName: group.TU_CLS_NM,
        hidden: false
      }
    })

    // const newGrpList = GrpList.map(group => {
    //   return {
    //     ...group,
    //     name: group.GrpName,
    //     displayName: group.name,
    //     hidden: false
    //   }
    // })

    // const newMidGrpList = MidGrpList.map(group => {
    //   return {
    //     ...group,
    //     name: group.GrpName,
    //     displayName: group.name,
    //     hidden: false
    //   }
    // })

    // const newSmGrpList = SmGrpList.map(group => {
    //   return {
    //     ...group,
    //     name: group.GrpName,
    //     displayName: group.name,
    //     hidden: false
    //   }
    // })

    let res = await okposItemOptionUpdate({
      shopId: shopInfo.shopId,
      optionName: itemOptions.optionName,
      body: {
        TuClsList: JSON.stringify(newTuClsList)
        // GrpList: JSON.stringify(newGrpList),
        // MidGrpList: JSON.stringify(newMidGrpList),
        // SmGrpList: JSON.stringify(newSmGrpList)
      }
    });
    if (res.err) throw Error(res.err.message + ` - 그룹리스트 초기화 중`);

    // 
    // reset itemList
    // 
    await Promise.all(itemList.map(async item => {
      const newItem = {
        itemName2: item.itemName,
        // nameLang: JSON.stringify([]),
        description: "",
        // descriptionLang: JSON.stringify([]),
        image: "",
        removed: false,
        isSoldout: item.SOLD_OUT_YN === "Y",
        isNew: false,
        isHot: false,
        isBest: false,
      }

      const res = await okposItemUpdate({ shopId: shopInfo.shopId, id: item.id, body: newItem })
      if (res.err) throw Error(res.err.message + ` - 메뉴항목 itemCode ${item.itemCode} 초기화 중`);
      return newItem;
    }));

    return {}
  } catch (e) {
    return { err: { message: "초기화 과정에서 에러가 발생하였습니다. 관리자에게 문의하세요!\n" + e.message } }
  }
}

/** @deprecated */
export function setMenuTextOkpos(props) {
  const { item, itemList, itemOptions, itemOptions2, usePosMenuFirst } = props

  let itemNames = []
  let setMenuText = ""

  // 
  // OKPOS SIDE MENU
  // 
  if (item.SIDE_MENU_YN === "Y") {
    // 
    // 속성 항목 있음 : 가격이 없다.
    // 
    if (item.SDA_CLS_CD !== "") {
      const sdaCdList = isJson(itemOptions["SdaCdList"]) ? JSON.parse(itemOptions["SdaCdList"]) : []

      // 
      // 속성항목리스트 SDA_CLS_CD 포함 아이템 추출 
      // 
      let sdaCdListForItem = sdaCdList.filter(sdaCd => sdaCd.SDA_CLS_CD === item.SDA_CLS_CD)
      itemNames = sdaCdListForItem.map(sdaCd => sdaCd.SDA_NM)
      setMenuText = itemNames.join(" | ")
    }

    // 
    // 선택 메뉴 있음 : 가격이 있을 수 있다.
    // 
    if (item.SDS_GRP_CD !== "") {
      const sdsClsList = isJson(itemOptions["SdsClsList"]) ? JSON.parse(itemOptions["SdsClsList"]) : []
      let sdsClsListForItem = sdsClsList.filter(sdsCls => sdsCls.SDS_GRP_CD === item.SDS_GRP_CD)
      itemNames = sdsClsListForItem.map(cls => cls.SDS_CLS_NM)
      setMenuText = itemNames.join(", ")
    }
  }

  // 
  // ONL 옵션 메뉴
  // 
  if (item.optionGroupCode) {
    let optionList = getOnlOptionItems({ item, itemOptions2 })
    itemNames = optionList.map(o => o.name)
    setMenuText = "오늘옵션 - " + itemNames.join(", ")
  }

  const maxLen = 50
  let finalText = setMenuText.length > maxLen
    ? setMenuText.substring(0, maxLen) + " ..."
    : setMenuText

  return finalText
}

/** @deprecated */
export async function getOkposMenuItemOptions({ shopInfo }) {
  try {
    let res = await okposItemOptionGet({ shopId: shopInfo.shopId })
    if (res.err) throw Error(res.err.message)
    const itemOptions = res.result.Item;

    res = await onlItemOptionGet({
      shopId: shopInfo.shopId,
      posName: shopInfo.posName
    })

    if (res.err) {
      if (res.err.message === "NO ITEM FOUND") {
        itemOptions.optionGroupList = JSON.stringify([])
        itemOptions.optionClassList = JSON.stringify([])
        itemOptions.optionItemList = JSON.stringify([])
      } else {
        throw Error(res.err.message)
      }
    } else {
      itemOptions.optionGroupList = res.result.Item.optionGroupList
      itemOptions.optionClassList = res.result.Item.optionClassList
      itemOptions.optionItemList = res.result.Item.optionItemList
    }

    return { result: { Item: itemOptions } }
  } catch (e) {
    return { err: { message: e.message } }
  }
}

/** @deprecated */
export function parseItemOptionsOkpos({ itemOptions }) {
  const names = [
    "TuClsList",
    "GrpList", "MidGrpList", "SmGrpList",
    "SdaCdList", "SdaClsList",
    "SdsCdList", "SdsClsList", "SdsGrList",
    "TableList", "menuSettings", "tableCallList",
    "optionGroupList", "optionClassList", "optionItemList",
  ]
  const itemOptions2 = { ...itemOptions }
  names.map(name => {
    itemOptions2[name] = isJson(itemOptions[name]) ? JSON.parse(itemOptions[name]) : []
  })
  return itemOptions2
}

/** @deprecated */
export function testOrderRequest({ shopInfo, item, itemOptions2, tableCode }) {
  const orderData = createOrderData({ item, itemOptions2, tableCode })
  const body = {
    storeCode: shopInfo.storeCode,
    shopId: shopInfo.shopId,
    orderData
  }
  return okposProxy({ apiName: "order", body })
}

/** @deprecated */
function createOrderData({ tableCode, item, itemOptions2 }) {
  const orderInfoTemplet = {
    "ORDER_TYPE": "Y",
    "PROD_CD": "",    // item.itemCode,
    "PROD_AMT": "",   // item.price,
    "DCM_AMT": "",    // item.price,
    "DC_AMT": "0",
    "POINT_AMT": "0",
    "TAX_AMT": "",    // item.price,
    "NOTAX_AMT": "0",
    "PROD_CNT": "1",
    "PROD_TYPE": "0", // 메뉴 타입 (0: 일반, 1: 선택, 2: 속성)

    "SDS_PTCD": "",
    "SDS_GRPCD": "",
    "SDS_CLSCD": "",
    "SDS_CD": "",
    "SDA_CLSCD": "",
    "SDA_CD": "",
    "SDS_ORG_DTL_NO": "",
  }

  const orderInfo = []
  const sdsOrgDtlNo = (item["SDA_CLS_CD"] !== "" || item["SDS_GRP_CD"] !== "")
    ? Math.floor(Math.random() * 10000).toString().padStart(4, "0")
    : ""

  // 
  // 메인 메뉴 정보 생성
  //  
  orderInfo.push({
    ...orderInfoTemplet,
    "PROD_CD": item.itemCode,
    "PROD_AMT": item.price,
    "DCM_AMT": item.price,
    "TAX_AMT": item.price,
    "SDS_ORG_DTL_NO": sdsOrgDtlNo
  })

  // 
  // 속성
  // 
  if (item["SDA_CLS_CD"] !== "") {
    const sdaCdList = itemOptions2["SdaCdList"]

    // 
    // 속성항목 추가
    // 
    let sdaCdListForItem = sdaCdList.filter(sdaCd => sdaCd.SDA_CLS_CD === item.SDA_CLS_CD)

    orderInfo.push({
      ...orderInfoTemplet,
      "PROD_TYPE": "2",
      "SDA_CLSCD": sdaCdListForItem[0]["SDA_CLS_CD"],
      "SDA_CD": sdaCdListForItem[0]["SDA_CD"],
      "SDS_ORG_DTL_NO": sdsOrgDtlNo
    })
  }

  // 
  // 선택항목 추가
  // 
  if (item["SDS_GRP_CD"] !== "") {
    const sdsClsList = itemOptions2["SdsClsList"]
    const sdsCdList = itemOptions2["SdsCdList"]

    const sdsClsListForItem = sdsClsList.filter(sdsCls => sdsCls.SDS_GRP_CD === item.SDS_GRP_CD)

    const listOfCdListForItem = sdsClsListForItem.map(cls => {
      const cdListForItem = sdsCdList.filter(cd => cd.SDS_CLS_CD === cls.SDS_CLS_CD)
      return cdListForItem
    })

    listOfCdListForItem.map(cdList => {
      const p = parseInt(cdList[0]["SDS_PROD_UPRC"]).toString()
      orderInfo.push({
        ...orderInfoTemplet,
        "PROD_CD": cdList[0]["PROD_CD"],
        "PROD_AMT": p,
        "DCM_AMT": p,
        "TAX_AMT": p,
        "PROD_TYPE": "1",
        "SDS_PTCD": item.itemCode,
        "SDS_GRPCD": item.SDS_GRP_CD,
        "SDS_CLSCD": cdList[0]["SDS_CLS_CD"],
        "SDS_CD": cdList[0]["SDS_CD"],
        "SDS_ORG_DTL_NO": sdsOrgDtlNo
      })
    })
  }

  // 
  // ONL 옵션 메뉴
  // 
  if (item["optionGroupCode"]) {
    let classList = getOnlOptionItems({ item, itemOptions2 })
    classList.map(cls => {
      if (cls.itemList2.length > 0) {
        orderInfo.push({
          ...orderInfoTemplet,
          "PROD_CD": cls.itemList2[0].itemCode,
          "PROD_AMT": cls.itemList2[0].price.toString(),
          "DCM_AMT": cls.itemList2[0].price.toString(),
          "TAX_AMT": cls.itemList2[0].price.toString(),
          "SDS_ORG_DTL_NO": sdsOrgDtlNo
        })
      }
    })
  }

  const orderData = {
    "TABLE_CD": tableCode,
    "KITCHEN_MEMO": "테스트 주문입니다.",
    "ORDER_INFO": orderInfo
  }

  return orderData
}

export async function getMasterInfo({ storeCode, setComment }) {
  try {

    const targets = [
      "PRODS",
      "SDACLCODE", "SDACDCODE",
      "SDSGRCODE", "SDSCLCODE", "SDSCDCODE",
      "CLSLMCODE", "CLSMMCODE", "CLSSMCODE",
      "TUCLSCODE",
      "TUKEYCODE",
      "TABLE"
    ]

    let errMsg = "";

    const masterInfo = {}

    for (let i = 0; i < targets.length; i++) {
      let res = await getTargetMaster({
        body: {
          'SHOP_CD': storeCode,
          'COUNT_PER_PAGE': '30',
          'CURRENT_PAGE_NUMBER': '1',
          'TARGET': targets[i]
        },
        setComment
      });
      if (res.err) {
        errMsg = `Fetching MasterInfo FAILED - ${res.err.message} at ${targets[i]}`
        break;
      }
      masterInfo[targets[i]] = res.result[targets[i]]
    }
    if (errMsg !== "") throw Error(errMsg)

    const validProdList = getValidProdList({ masterInfo })

    let mergedProds = mergeProdsTukey({ prodList: validProdList, tukeyList: masterInfo["TUKEYCODE"] })
    mergedProds = sortObjArray({ arr: mergedProds, key: "PROD_CD" })
    masterInfo["PRODS"] = mergedProds

    return { result: { Item: masterInfo } }
  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } }
  }
}

/**
 * 
 * @param {*} param.body {SHOP_CD, COUNT_PER_PAGE, CURRENT_PAGE_NUMBER, TARGET}
 * @returns 
 */
export async function getTargetMaster({ body, targetData = [], setComment, totalPages = "1", retryCnt = 0 }) {
  try {
    // 
    // delay for loop masterInfo
    // 
    await delay(delayTime)

    const targetName = body["TARGET"]

    let comment = `Receiving ${targetName} page ${body['CURRENT_PAGE_NUMBER']}`
    if (parseInt(totalPages) > 1) {
      comment += ` of total ${totalPages} pages`
    }
    if (retryCnt > 0) comment += `Retry ${retryCnt}`
    setComment(comment)

    let res = await okposProxy({ apiName: "targetMasterInfo", body });
    if (res.err) throw Error(res.err.message);

    const result = {}
    const d = res.result
    if (d.RESULT_CODE !== "0000") {

      // 
      // 요청한 페이지에 데이터가 없음  생성된 페이지 수를 넘긴 값을 요청할 경우
      // 
      if (d.RESULT_CODE === "8300") {
        result[targetName] = []
        return { result }
      }

      // 
      // retry
      // 
      if (d.RESULT_CODE === "9003") {

        if (retryCnt > 3) throw Error(getErrorMsg(res));
        return await getTargetMaster({
          body,
          targetData,
          setComment,
          totalPages,
          retryCnt: retryCnt + 1
        })
      }
      throw Error(getErrorMsg(res)); // POS res 에러 처리
    }
    const newTargetData = [...targetData, ...d.DATA[targetName]]

    if (d.CURRENT_PAGE_NUMBER !== d.TOTAL_PAGE_COUNT) {
      // await delay(delayTime)
      const newBody = {
        ...body,
        'CURRENT_PAGE_NUMBER': (parseInt(body.CURRENT_PAGE_NUMBER) + 1).toString(),
      }

      return await getTargetMaster({
        body: newBody,
        targetData: newTargetData,
        setComment,
        totalPages: d.TOTAL_PAGE_COUNT
      })
    } else {
      retryCnt = 0
      result[targetName] = newTargetData
      return { result }
    }
  } catch (e) {
    return { err: { message: e.message } }
  }
}

/**
 * prod이 SDA 혹은 SDS에 따라 또 다른 item을 사용할 경우 추가하여 리턴
 * 
 */
function getProdsInUse({ tukey, prod, menu }) {
  let prodsInUse = [prod]

  if (prod["SIDE_MENU_YN"] === "Y") {

    // 
    // 선택
    // 
    if (prod["SDS_GRP_CD"] !== "") {
      const sdsClsList = menu["SDSCLCODE"]
      const sdsCdList = menu["SDSCDCODE"]

      let sdsClsListForItem = sdsClsList.filter(sdsCls => sdsCls.SDS_GRP_CD === prod.SDS_GRP_CD)

      sdsClsListForItem.map(cls => {
        const sdsCdListForItem = sdsCdList.filter(cd => cd.SDS_CLS_CD === cls.SDS_CLS_CD)
        const prods = sdsCdListForItem.map(sdsCd => {
          const _prods = menu.PRODS.filter(prod => prod.PROD_CD === sdsCd.PROD_CD)
          if (_prods.length === 1) return _prods[0]
          return null
        })
        prodsInUse = [...prodsInUse, ...prods]
      })
    }
  }
  return prodsInUse
}

function getValidProdList({ masterInfo }) {
  // if (debug) {
  //   console.log("PRODS len    ", masterInfo["PRODS"]?.length)
  //   console.log("SDACLCODE len", masterInfo["SDACLCODE"]?.length)
  //   console.log("SDACDCODE len", masterInfo["SDACDCODE"]?.length)
  //   console.log("SDSGRCODE len", masterInfo["SDSGRCODE"]?.length)
  //   console.log("SDSCLCODE len", masterInfo["SDSCLCODE"]?.length)
  //   console.log("SDSCDCODE len", masterInfo["SDSCDCODE"]?.length)
  //   console.log("CLSLMCODE len", masterInfo["CLSLMCODE"]?.length)
  //   console.log("CLSMMCODE len", masterInfo["CLSMMCODE"]?.length)
  //   console.log("CLSSMCODE len", masterInfo["CLSSMCODE"]?.length)
  //   console.log("TUCLSCODE len", masterInfo["TUCLSCODE"]?.length)
  //   console.log("TUKEYCODE len", masterInfo["TUKEYCODE"]?.length)
  // }

  let validProdList = []

  masterInfo["TUKEYCODE"].map((tukey, i) => {

    // 
    // TUKEY로부터 prod 획득
    //
    const prods = masterInfo["PRODS"].filter(prod => tukey.PROD_CD === prod.PROD_CD)
    if (prods.length !== 1) return null

    // 
    // SDS list 로부터 prodInUse list 획득
    // 
    const prodsInUse = getProdsInUse({ tukey, prod: prods[0], menu: masterInfo })
    validProdList = [...validProdList, ...prodsInUse]
    return null
  })

  if (debug) {
    console.log('validProdList len', validProdList.length)
  }

  // 
  // validProdList 에 이미 있으면 제거
  // 
  const validProdList2 = getUniqueArray({ arr: validProdList, key: "PROD_CD" })
  if (debug) console.log('validProdList2 len', validProdList2.length)
  return validProdList2
}

function mergeProdsTukey({ prodList, tukeyList }) {
  if (prodList.length === 0 || tukeyList.length === 0) return []
  const newPosItems = prodList.map(prod => {
    const tuListForItem = tukeyList.filter(tu => tu.PROD_CD === prod.PROD_CD)
    if (tuListForItem.length > 0) {
      prod.tuClassList = tuListForItem.map(tu => tu.TU_CLS_CD)
    } else {
      prod.tuClassList = []
    }
    return prod
  })
  return newPosItems
}

function getUniqueArray({ arr, key }) {
  const newArr = []
  arr.map(item => {
    const items = newArr.filter(newItem => newItem[key] === item[key])
    if (items.length === 0) newArr.push(item)
    return null
  })
  return newArr
}

function getErrorMsg(res) {
  let errMsg = res.result.RESULT_CODE;
  errMsg += " - " + res.result.RESULT_MSG;
  errMsg += " at " + res.result.COMMAND;
  return errMsg
}

export async function removeOptionGroupCode({ itemList, optionGroupCode }) {
  const itemsWithCode = itemList.filter(o => o.optionGroupCode === optionGroupCode)

  let errMsg = ""
  await Promise.all(itemsWithCode.map(async item => {
    let res = okposItemUpdate({
      shopId: item.shopId,
      id: item.id,
      body: { optionGroupCode: "" }
    })
    if (res.err) errMsg += res.err + " at " + item.itemCode + " update"
  }))

  if (errMsg) return { err: { message: errMsg } }
  else return {}
}

/**
 * @info cleanup onlOption.optionItemList | okposOption.TuClsList
 */
export async function cleanupOnlOptionItemList({ shopInfo, itemList, setComment }) {
  try {

    setComment(`Updating OnlItemOption...`)

    let res = await onlItemOptionGet({ shopId: shopInfo.shopId, posName: shopInfo.posName })
    if (res.err) {
      if (debug) console.log('res onlItemOptionGet', res)
      if (res.err.message === "NO ITEM FOUND") return {}
      else throw new Error(res.err.message);
    }
    const onlItemOptions = parseItemOptionsOkpos({ itemOptions: res.result.Item })
    // const onlItemOptions = parseItemOptions({ shopInfo, itemOptions: res.result.Item })

    // 
    // item 삭제 -> optionItemList에 있으면 삭제
    // 
    const newOptItemList = []
    onlItemOptions.optionItemList.map(optItem => {
      const items = itemList.filter(item => item.itemCode === optItem.itemCode)

      if (items.length === 1) newOptItemList.push({
        itemCode: items[0].itemCode,
        name: items[0].itemName,
        price: parseInt(items[0].price),
        optionPrice: parseInt(items[0].price)
      })
      return null
    })

    // 
    // 해당 optionClassList에 있으면 삭제
    // 
    const newOptClassList = onlItemOptions.optionClassList.map(optCls => {
      const newItemList = optCls.itemList.filter(code => {
        return newOptItemList.filter(item => item.itemCode === code).length === 1
      })
      return { ...optCls, itemList: newItemList }
    })

    const params = {
      shopId: shopInfo.shopId,
      posName: shopInfo.posName,
      body: {
        optionItemList: JSON.stringify(newOptItemList),
        optionClassList: JSON.stringify(newOptClassList),
      }
    }
    res = await onlItemOptionUpdate(params)
    if (res.err) throw Error(res.err.message)

    return {}
  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } }
  }
}

/**
 * @info cleanup okposOption.TuClsList > itemList
 */
export async function cleanupTuClsItemList({ shopInfo, itemList, setComment }) {
  try {
    setComment(`Cleanup TuClsList itemList...`)

    let res = await okposItemOptionGet({ shopId: shopInfo.shopId })
    if (res.err) throw new Error(res.err.message);

    // const itemOptions2 = parseItemOptionsOkpos({ itemOptions: res.result.Item })
    const itemOptions2 = parseItemOptions({ shopInfo, itemOptions: res.result.Item })
    const newTuClsList0 = [...itemOptions2.TuClsList]

    const newTuClsList = makeUniqueTuClsList(newTuClsList0)

    // 
    // TuCls.itemList 에서 itemCode 삭제 | 추가
    // 
    for (let i = 0; i < newTuClsList.length; i++) {
      const key = "TU_CLS_CD"
      const tuClsCode = newTuClsList[i][key]
      const tuItemList = newTuClsList[i].itemList || []

      // 
      // item 삭제사 itemList에서 삭제
      // 
      let newTuItemList = tuItemList.filter(code => {
        return itemList.filter(item => item.itemCode === code).length > 0
      })

      // 
      // 순서와 상관없는 itemList
      // 
      const items = itemList.filter(item => item.tuClassList?.includes(tuClsCode))
      const newTuItemList2 = items.map(o => o.itemCode)

      const finalTuItemList = []

      // 
      // fullItemList 에 있어야 함
      // 
      newTuItemList.map((code, i) => {
        if (newTuItemList2.includes(code)) finalTuItemList.push(code)
        return null;
      })

      // 
      // 위에서 기존 itemList 순서에 의해 먼저 리스터업한 후, 나중에 추가함
      // 
      newTuItemList2.map(code => {
        if (!newTuItemList.includes(code)) finalTuItemList.push(code)
        return null;
      })

      // 
      // newTuItemList 예   = [ 10, 1, 3, 5, 7, 11 ]
      // newTuItemList2 예  = [ 1, 2, 5, 7, 9, 10 ]
      // fullItemList 예    = [ 10, 1, 5, 7, 9, 2 ]   -> 3, 11 은 제외됨
      // 
      newTuClsList[i].itemList = finalTuItemList
    }

    if (debug) logTuClsList(newTuClsList)

    res = await okposItemOptionUpdate({
      shopId: shopInfo.shopId,
      body: { TuClsList: JSON.stringify(newTuClsList) },
    })
    if (res.err) throw new Error(res.err.message);

    return {}
  } catch (e) {
    if (debug) console.log(e)
    console.log(e)
    return { err: { message: e.message } }
  }
}

function logTuClsList(itemList) {
  const names = ["TU_CLS_CD", "TU_CLS_NM", "itemList",]

  const newItemList = itemList.map(item => {
    const newItem = {}
    Object.keys(item).map(key => {
      if (names.includes(key)) newItem[key] = item[key]
      // if (key === "tuClassList") newItem[key] = JSON.stringify(item[key])
    })
    return newItem
  })
  console.table(newItemList)
}

function makeUniqueTuClsList(tuClsList) {
  const newList = []

  tuClsList.map(cls => {
    const clses = newList.filter(c => c.TU_CLS_CD === cls.TU_CLS_CD)
    if (clses.length === 0) newList.push(cls)
    return null
  })

  console.log('\n', '='.repeat(50), "TuClsList")
  console.table(tuClsList)
  console.table(newList)

  return newList
}
