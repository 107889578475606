import conf from "../conf";
const debug = conf.debug;

export const shopType = ['음식점', '카페', '테이크아웃 전문점', '기타'];
export const posList = [
  "UNIONPOS",
  "OKPOS",
  "EASY_POS",
  "OASIS_POS",
  // "SMILEPOS", 
  // "DUMMY_POS"
];

if (debug) posList.push("DUMMY_POS")

export const shopPosname = {
  inputFields: [
    {
      name: "posName",
      displayName: "사용 포스 소프트웨어 - 필수",
      inputType: "select",
      selectList: posList,
      defaultValue: posList[0],
    },
  ]
}


/**
 * @shop_속성
 *  phone_number는 user 있으므로 제외
 */
const shop = {
  inputFields: [
    {
      name: "shopName",
      displayName: "가맹점 명 - 필수",
      defaultValue: debug ? "PSDL 레스토랑" : "",
    },
    {
      name: "shopType",
      displayName: "가게 분류 - 필수",
      selectList: shopType,
      inputType: "select",
      defaultValue: shopType[0],
    },
    {
      name: "ownername",
      displayName: "대표자 이름 - 필수",
      defaultValue: debug ? "이기봉" : "",
    },
    {
      name: "tableCount",
      displayName: "테이블 숫자",
      type: "number",
      defaultValue: 10,
      comment: "4인 테이블 기준",
      required: false,
    },
    {
      name: 'zipcode',
      displayName: "가게위치",
      comment: "우편번호",
      required: false,
      defaultValue: debug ? "13449" : "",
    },
    {
      name: 'addr1',
      comment: "도로명 주소 혹은 지번 주소",
      required: false,
      defaultValue: debug ? "경기 성남시 수정구 대왕판교로 815" : "",
    },
    {
      name: 'addr2',
      comment: "상세 주소. (예: 건물의 동/호수)",
      required: false,
      defaultValue: debug ? "정보보호클러스트 466호" : "",
    },
    {
      name: "country",
      displayName: "국가명",
      defaultValue: "대한민국",
      editable: false,
      required: false,
    },
    {
      name: 'tel',
      displayName: "유선 전화번호",
      placeholder: "'-' 없이 번호만",
      required: false,
      defaultValue: debug ? "031-" : "",
    },
    {
      name: "image",
      type: 'file',     // html.input 의 속성
      inputType: "file", // FormInput 의 component 속성
      displayName: "가맹점 스토어 사진",
      required: false
    },
  ],
};

export const shopUnionpos = {
  inputFields: [
    {
      name: "licenseNo",
      displayName: "사업자 번호 - 필수",
      editable: false, // isEdit 시 수정 불가
      // editable: true,
      placeholder: "'-' 없이 번호만",
      // validate: { type: "licenseNo" },
      defaultValue: debug ? "1028146446(11)" : "",
      // defaultValue: debug ? "5288701357" : "",
    },
    ...shop.inputFields
  ],
};

export const shopSmilepos = {
  inputFields: [
    {
      name: "storeCode",
      displayName: "포스에 등록된 가맹점 코드 - 필수",
      editable: false, // isEdit 시 수정 불가
      placeholder: "스토어 코드",
      defaultValue: debug ? "3100488001" : "",
    },
    ...shop.inputFields
  ]
}

/**
 * 테스트
 * username: shop4673ok, pw: psdl12@$
 * shopName: PSDL OKPOS Test Shop
 */
export const shopOkpos = {
  inputFields: [
    {
      name: "storeCode",
      displayName: "포스에 등록된 가맹점 코드(SHOP_CD) - 필수",
      editable: false, // isEdit 시 수정 불가
      placeholder: "스토어 코드",
      defaultValue: debug ? "" : "",
    },
    ...shop.inputFields
  ]
}

export const shopOasispos = {
  inputFields: [
    {
      name: "storeCode",
      displayName: "포스에 등록된 가맹점 코드(Branch) - 필수",
      editable: false, // isEdit 시 수정 불가
      placeholder: "스토어 코드",
      defaultValue: debug ? "" : "",
    },
    ...shop.inputFields
  ]
}

export const shopEasypos = {
  inputFields: [
    {
      name: "storeCode",
      displayName: "포스에 등록된 SHOP_NO - 필수",
      // editable: false, // isEdit 시 수정 불가
      placeholder: "스토어 코드",
      defaultValue: debug ? "000205" : "",
    },
    ...shop.inputFields,
    {
      name: "easypos_headOfficeNo",
      displayName: "Head Office No - 필수",
      // editable: false, // isEdit 시 수정 불가
      defaultValue: debug ? "" : "",
    },
    {
      name: "easypos_posNo",
      displayName: "마스터 데이터 요청용 포스 번호 - 필수",
      // editable: false, // isEdit 시 수정 불가
      defaultValue: debug ? "" : "",
    },
    {
      name: "easypos_masterDataKey",
      displayName: "마스터 데이터 요청에 필요한 키 값 - 필수",
      // editable: false, // isEdit 시 수정 불가
      defaultValue: debug ? "" : "",
    },

  ]
}