import React, { useEffect, useState } from "react";
import { ReactComponent as Smile } from "../../../assets/icons/Smile.svg";
import { useStyles, OnlDropdown } from "../../../components";
import { Pencil, Trash, Recycle, Clock } from "../../../components/Icons";
import { getPriceStr, getTnUrl, isJson } from "../../../lib/utils";
import { Texts } from "../Texts";
import ModifyMenuModal from "./ModifyMenuModal";
import ModifyItemServiceTimeModal from "./ModifyServiceTimeModal";
import ModalAddOptionGroup from "./AddOptionGroupModal";
import { getSetmenuText, updateItem } from "../../../lib-pos";
import { getItemName, getItemDescription } from "../../../lib-pos";
import { debug } from "../../../settings";
import { TestOrderModal } from "./TestOrder/TestOrderModal2";
import { useSelector } from "react-redux";
import { serviceTimeSet } from "./shop-utils";

export default function MenuItem(props) {
  const {
    shopInfo,
    item,
    usePosMenuFirst,
    itemOptions2,
    itemList,
    setIsLoading,
    onload
  } = props;

  const { selectedLanguage } = useSelector(state => state.shop)

  const [visibleModifyMenuModal, setVisibleModifyMenuModal] = useState(false);
  const [visibleServiceTime, setVisibleServiceTime] = useState(false);
  const [setmenuText, setSetmenuText] = useState("");
  const [itemName, setItemName] = useState(item.itemName)
  const [visibleDD, setVisibleDD] = useState(false); // dropDown show
  const [imgUrl, setImgUrl] = useState("");

  // 
  // testOrderRequest
  // 
  const [visibleOrderModal, setVisibleOrderModal] = useState(false);

  // 
  // Add Onl Option Group
  // 
  const [visibleOnlOption, setVisibleOnlOption] = useState(false);

  const [itemSTime, setItemSTime] = useState(false)

  useEffect(() => {
    function start() {
      try {
        setItemName(getItemName({ shopInfo, item, usePosMenuFirst, selectedLanguage }))

        let res = getSetmenuText({ shopInfo, item, itemList, itemOptions2, usePosMenuFirst });
        if (res.err) throw Error(res.err.message)
        setSetmenuText(res.result);

        setImgUrl(getTnUrl(item.image))

        let serviceTime = {}
        if (isJson(item.serviceTime)) serviceTime = JSON.parse(item.serviceTime);
        setItemSTime(serviceTimeSet(serviceTime))

      } catch (e) {
        alert(e.message)
        if (debug) console.log(e)
      }
    }
    start()
  }, [itemList, itemOptions2, item]);

  const onChangeBoolValue = async (type) => {
    try {
      setIsLoading(true);
      setVisibleDD(!visibleDD);

      let body = {};

      if (type === "removed") body.removed = !item.removed;
      if (type === "isSoldout") body.isSoldout = !item.isSoldout;
      if (type === "isNew") body.isNew = !item.isNew;
      if (type === "isHot") body.isHot = !item.isHot;
      if (type === "isBest") body.isBest = !item.isBest;

      if (Object.keys(body).length === 0) throw new Error("메뉴변경에 에러가 있습니다!");

      let res = await updateItem({ shopInfo, id: item.id, body });
      if (res.err) throw new Error("저장에 실패 하였습니다.!\n" + res.err.message);
      if (debug) console.log('res', res);
      await onload();
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  const otherProps = {
    shopInfo,
    setVisibleModifyMenuModal,
    setVisibleServiceTime,
    onChangeBoolValue,
    setVisibleOrderModal,
    setmenuText, imgUrl, usePosMenuFirst, itemName,
    setVisibleOnlOption,
    itemSTime
  }

  const myStyle = {
    itemContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }
  }

  return (
    <div style={myStyle.itemContainer}>

      {/* 메뉴item 정보 */}
      <ItemInfo {...props} {...otherProps} />

      {/* item 편집 */}
      <ItemDropDown {...props} {...otherProps} />

      {visibleModifyMenuModal &&
        <ModifyMenuModal
          {...props}
          visible={visibleModifyMenuModal}
          setIsVisible={setVisibleModifyMenuModal} />
      }

      {visibleServiceTime &&
        <ModifyItemServiceTimeModal
          {...props}
          visible={visibleServiceTime}
          setIsVisible={setVisibleServiceTime} />
      }

      {(visibleOnlOption) &&
        <ModalAddOptionGroup {...props}
          visible={visibleOnlOption}
          setIsVisible={setVisibleOnlOption} />
      }

      {(visibleOrderModal) &&
        <TestOrderModal {...props}
          visible={visibleOrderModal}
          setIsVisible={setVisibleOrderModal} />
      }

    </div>
  )
}

function ItemInfo(props) {
  const { item, itemName, setmenuText, imgUrl, usePosMenuFirst, itemSTime, setVisibleServiceTime } = props

  const { selectedLanguage } = useSelector(state => state.shop)
  const { gStyle, colors } = useStyles();
  const { isMobilePhone } = useSelector(state => state.platform)

  const imgSize = isMobilePhone ? 80 : 100;

  const myStyle = {
    itemInfo: {
      display: 'flex',
      alignItems: "flex-start" // 이미지가 늘어나는 것을 방지함. default 값이 stretch 이므로...
    },
    bgImg: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: imgSize,
      height: imgSize,
      borderRadius: 8,
    },
    img: {
      width: imgSize,
      // border: "1px solid #ddd",
      borderRadius: 8
    },
    textInfo: { ...gStyle.text },
    textInfoInline: { ...gStyle.text, display: "inline" },
    textSoldout: { ...gStyle.text, color: colors.danger, ...gStyle.bold },
    textHighLight: { ...gStyle.text, ...gStyle.highLight, display: "inline" },
  }

  return (
    < div style={myStyle.itemInfo} >
      {
        imgUrl
          // ? <div style={{ ...myStyle.bgImg, backgroundImage: `url(${imgUrl})` }} />
          ? <img style={myStyle.img} src={imgUrl} alt={"menu" + item.itemCode} />
          : <Smile width={imgSize} height={imgSize} />}
      <div style={{ marginLeft: 20, textAlign: 'left' }}>

        {itemSTime &&
          <p style={{ ...myStyle.textInfoInline, marginRight: 5 }}>
            <Clock
              style={{ fontSize: "14px", color: colors.danger }}
              onClick={setVisibleServiceTime}
            />
          </p>
        }

        <p style={myStyle.textInfoInline}>
          {itemName}
          {setmenuText ? " (" + setmenuText + ")" : ""}
        </p>

        {item.removed && <p style={myStyle.textSoldout}>{Texts.menuItem.hidden}</p>}
        {
          !item.removed &&
          <>
            <p style={myStyle.textInfo}>{getPriceStr(item.price)} 원</p>

            {item.isSoldout &&
              <p style={myStyle.textSoldout}>{Texts.shopMenu.haveNoItem}</p>}

            {(item.isNew && !item.isSoldout) &&
              <p style={myStyle.textHighLight}>{Texts.menuItem.new} </p>}

            {(item.isHot && !item.isSoldout) &&
              <p style={myStyle.textHighLight}>{Texts.menuItem.hot} </p>}

            {(item.isBest && !item.isSoldout) &&
              <p style={myStyle.textHighLight}>{Texts.menuItem.best} </p>}

            {!item.isSoldout &&
              <p style={myStyle.textInfoInline}>
                {getItemDescription({ item, usePosMenuFirst, selectedLanguage })}
              </p>
            }
          </>
        }
      </div>
    </div>
  )
}

function ItemDropDown(props) {
  const {
    shopInfo,
    setVisibleModifyMenuModal,
    setVisibleServiceTime,
    onChangeBoolValue,
    setVisibleOrderModal, setVisibleOkposModal,
    setVisibleOnlOption,
    item
  } = props;

  const testOrderPoses = [
    "UNIONPOS",   // unionpos
    "OASIS_POS",   // OASIS_POS
    "EASY_POS",   // EASY_POS
    "DUMMY_POS",   // EASY_POS
  ]

  const testShopId = "43a047780a6478c1c9c4" // dpos test

  return (
    <div>
      <OnlDropdown title="" id="menu-setting" width="180px">

        {/* {(debug && testOrderPoses.includes(shopInfo.posName)) && */}
        {((debug && testOrderPoses.includes(shopInfo.posName)) || (shopInfo.shopId === testShopId)) &&
          <OnlDropdown.Item onClick={() => setVisibleOrderModal(true)}>
            테스트 주문하기
          </OnlDropdown.Item>
        }

        {debug &&
          <OnlDropdown.Divider />}

        <OnlDropdown.Item onClick={() => setVisibleModifyMenuModal(true)}>
          <Pencil /> 메뉴정보 편집
        </OnlDropdown.Item>

        {(shopInfo.posName === "OKPOS" || shopInfo.posName === "DUMMY_POS") &&
          <OnlDropdown.Item onClick={() => setVisibleOnlOption(true)}>
            <Pencil /> {shopInfo.posName === "OKPOS" ? "ONL 옵션메뉴 설정" : "옵션메뉴 설정"}
          </OnlDropdown.Item>}

        <OnlDropdown.Item onClick={() => setVisibleServiceTime(true)}>
          <Clock /> 서비스시간 세팅
        </OnlDropdown.Item>

        <OnlDropdown.Divider />

        <OnlDropdown.Item onClick={() => onChangeBoolValue("removed")}>
          {item.removed ? <Recycle /> : <Trash />}
          &nbsp;
          {item.removed ? "숨기기 취소" : "메뉴 숨기기"}
        </OnlDropdown.Item>

        <OnlDropdown.Divider />

        <OnlDropdown.Item onClick={() => onChangeBoolValue("isBest")}>
          {item.isBest ? "Best 취소" : "Best 등록"}
        </OnlDropdown.Item>

        <OnlDropdown.Item onClick={() => onChangeBoolValue("isHot")}>
          {item.isHot ? "Hot 취소" : "Hot 등록"}
        </OnlDropdown.Item>

        <OnlDropdown.Item onClick={() => onChangeBoolValue("isNew")}>
          {item.isNew ? "New 취소" : "New 등록"}
        </OnlDropdown.Item>

        <OnlDropdown.Item onClick={() => onChangeBoolValue("isSoldout")}>
          {item.isSoldout ? "Soldout 취소" : "Soldout 등록"}
        </OnlDropdown.Item>

      </OnlDropdown>
    </div>
  )
}
