import { axiosCall } from "../../lib/ec2-api-lib";
// import { debug } from "../../settings";

export const dposProxy = async ({ apiName, body }) => {
  // 
  // apiNames: 
  //  "order": "",           // 주문
  //  "orderInq": "",        // 주문 확인
  // 
  let path = `/pos/dpos?apiName=${apiName}`;
  path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};