import { kiloComma, isJson } from "../lib/utils";
import { createItemObj } from "./createItemObj";
import { debug } from "../settings";

const pos = {
  "UNIONPOS": {
    transformOrders: transformOrdersUnionpos,
    getItemHtmlInfo: getHtmlUnionpos,
  },
  "OKPOS": {
    transformOrders: transformOrdersOkpos,
    // getItemHtmlInfo: notSupported,
    getItemHtmlInfo: () => "",
  },
  "DUMMY_POS": {
    transformOrders: transformOrdersDpos,
    getItemHtmlInfo: getHtmlDpos,
  },
  "OASIS_POS": {
    transformOrders: transformOrdersOasis,
    getItemHtmlInfo: getHtmlOasis,
  },
  "EASY_POS": {
    transformOrders: transformOrdersEasy,
    getItemHtmlInfo: getHtmlEasy,
  }
}

const posList = Object.keys(pos)

export function getItemHtmlInfo({ shopInfo, ...rest }) {
  if (!posList.includes(shopInfo.posName)) return 'NOT SUPPORTED'
  return pos[shopInfo.posName].getItemHtmlInfo({ shopInfo, ...rest });
}

export function transformOrders({ shopInfo, ...rest }) {
  if (!posList.includes(shopInfo.posName)) return []
  return pos[shopInfo.posName].transformOrders({ shopInfo, ...rest });
}


/** @DUMMY_POS */

function transformOrdersDpos({ shopInfo, orders, tableList, itemList, itemOptions, prePay = false }) {
  let newOrders = []

  orders.map((order) => {

    let orderData = JSON.parse(order.orderData);
    order.orderData = orderData

    if (prePay && isJson(order.payData)) order.payDataObj = JSON.parse(order.payData);

    let tables = tableList.filter(table => table.tableCode === orderData.tableCode)

    const newOrder = {
      time: order.created,
      orderInfoHtml: getHtmlDpos({ shopInfo, orderData, itemList, itemOptions }),
      TableName: ""
    }

    if (tables.length === 1) {
      newOrder.TableName = tables[0].tableName
    } else {
      newOrder.TableName = orderData.tableCode + " - 삭제된 테이블"
    }
    newOrders.push(newOrder)

    return null
  });

  // console.table(newOrders)

  return newOrders
}


function getHtmlDpos({ orderData, itemList }) {

  const orderItemList = orderData.orderList

  let htmlTxt = `<div>`
  orderItemList.map(order => {
    const idx = itemList.findIndex(item => item.itemCode === order.itemCode)
    if (idx >= 0) {
      const { itemAmt, itemTxt, optTxt } = getOrderItemInfoDpos({ order })
      htmlTxt += getItemLineHtml({ qty: order.qty, itemTxt, optTxt, itemAmt })
    } else {
      htmlTxt += `<div><p style="margin: 0px">Menu Item Not Found</p></div>`
    }
    return null
  })
  htmlTxt += `</div>`
  return htmlTxt
}


export const getOrderItemInfoDpos = ({ order }) => {
  let optAmt = 0
  const optNames = []
  if (order.optionList?.length > 0) {
    order.optionList.map(opt => {
      // console.table(opt)
      if (opt.qty > 0) {
        optNames.push(opt.optionItemName + " x " + opt.qty)
        optAmt += opt.qty * opt.price
      }
      return null
    })
  }
  const optTxt = optNames.join(", ")
  const itemAmt = parseInt(order.price * order.qty) + optAmt
  return { itemAmt, itemTxt: order.itemName, optTxt }
}


/** @EASY_POS */

function transformOrdersEasy({ shopInfo, orders, tableList, itemList, itemOptions, prePay = false }) {
  let newOrders = []

  orders.map((order) => {

    let orderData = JSON.parse(order.orderData);
    order.orderData = orderData

    if (prePay && isJson(order.payData)) order.payDataObj = JSON.parse(order.payData);

    let tables = tableList.filter(table => table.tableCode === orderData.tableCode)

    const newOrder = {
      ...order,
      time: order.created,
      orderInfoHtml: getHtmlEasy({ shopInfo, orderData, itemList, itemOptions }),
      payInfo: prePay ? _getPayInfoEasy(order) : "",
    }
    if (tables.length === 1) {
      newOrder.TableName = tables[0].tableName
    } else {
      newOrder.TableName = orderData.TableCode + " - 삭제된 테이블"
    }

    // 
    // payData 이면 orderStatus===true 일때만 주문성공임
    // 
    if (prePay) {
      if (order.isOrdered && order.isPaid) newOrders.push(newOrder)
    }
    else newOrders.push(newOrder)
    return null
  });
  return newOrders
}

const _getPayInfoEasy = (order) => {
  let info = ""
  // let payData = {}
  // if (order.payData && isJson(order.payData)) {
  //   payData = JSON.parse(order.payData)
  //   order.payData = payData

  //   if (order.isPaid) info += "결제완료 - "
  //   info += `${kiloComma(order.orderData?.orderAmt)}원`

  //   if (order.isPayCanceled) info += " - 결제취소"
  // }
  // if (debug) console.log("order", order)
  return info
}

function getHtmlEasy({ shopInfo, orderData, itemList, itemOptions }) {

  const orderItemList = orderData.itemList

  let htmlTxt = `<div>`
  orderItemList.map(order => {

    if (debug) console.log('='.repeat(50), "HTML Line 생성")
    if (debug) console.log('order', order)

    const idx = itemList.findIndex(item => item.itemCode === order.itemCode)
    if (idx >= 0) {
      const { itemAmt, itemTxt, optTxt } = getOrderItemInfoEasy({ shopInfo, order, item: itemList[idx], itemList, itemOptions })
      htmlTxt += getItemLineHtml({ qty: order.qty, itemTxt, optTxt, itemAmt })
    } else {
      htmlTxt += `<div><p style="margin: 0px">Menu Item Not Found</p></div>`
    }
    return null
  })
  htmlTxt += `</div>`
  return htmlTxt
}

export const getOrderItemInfoEasy = ({ shopInfo, order, item, itemList, itemOptions }) => {
  let optAmt = 0
  const optNames = []
  // if (order.optionList?.length > 0) {
  //   order.optionList.map(opt => {
  //     // console.table(opt)
  //     if (opt.qty > 0) {
  //       optNames.push(opt.mName + " x " + opt.qty)
  //       optAmt += opt.qty * opt.amount
  //     }
  //     return null
  //   })
  // }
  const optTxt = optNames.join(", ")
  const itemAmt = parseInt(order.itemPrice * order.qty) + optAmt
  return { itemAmt, itemTxt: item.itemName, optTxt }
}


/** @OASIS_POS */

function transformOrdersOasis({ shopInfo, orders, tableList, itemList, itemOptions, prePay = false }) {
  let newOrders = []

  orders.map((order) => {

    let orderData = JSON.parse(order.orderData);
    order.orderData = orderData

    if (prePay && isJson(order.payData)) order.payDataObj = JSON.parse(order.payData);

    let tables = tableList.filter(table => table.tableNo === parseInt(orderData.tableNo))

    const newOrder = {
      ...order,
      time: order.created,
      orderInfoHtml: getHtmlOasis({ shopInfo, orderData, itemList, itemOptions }),
      payInfo: prePay ? _getPayInfoOasis(order) : "",
    }
    if (tables.length === 1) {
      newOrder.TableName = tables[0].tableName
    } else {
      newOrder.TableName = orderData.TableCode + " - 삭제된 테이블"
    }

    // 
    // payData 이면 orderStatus===true 일때만 주문성공임
    // 
    if (prePay) {
      if (order.isOrdered && order.isPaid) newOrders.push(newOrder)
    }
    else newOrders.push(newOrder)
    return null
  });
  return newOrders
}

const _getPayInfoOasis = (order) => {
  let info = ""
  let payData = {}
  if (order.payData && isJson(order.payData)) {
    payData = JSON.parse(order.payData)
    order.payData = payData

    if (order.isPaid) info += "결제완료 - "
    info += `${kiloComma(order.orderData?.orderAmt)}원`

    if (order.isPayCanceled) info += " - 결제취소"
  }
  // if (debug) console.log("order", order)
  return info
}

function getHtmlOasis({ shopInfo, orderData, itemList, itemOptions }) {

  const OrderData = orderData.orderList

  let htmlTxt = `<div>`
  OrderData.map(order => {

    if (debug) console.log('*'.repeat(100))
    if (debug) console.log('order', order)

    const idx = itemList.findIndex(item => item.itemCode === order.mCode)
    if (idx >= 0) {
      const { itemAmt, itemTxt, optTxt } = getOrderItemInfoOasis({ shopInfo, order, item: itemList[idx], itemList, itemOptions })
      htmlTxt += getItemLineHtml({ qty: order.qty, itemTxt, optTxt, itemAmt })
    } else {
      htmlTxt += `<div><p style="margin: 0px">Menu Item Not Found</p></div>`
    }
    return null
  })
  htmlTxt += `</div>`
  return htmlTxt
}

// 
// order 하나에 대한 정보
// 
export const getOrderItemInfoOasis = ({ shopInfo, order, item, itemList, itemOptions }) => {
  let optAmt = 0
  const optNames = []
  if (order.optionList?.length > 0) {
    order.optionList.map(opt => {
      // console.table(opt)
      if (opt.qty > 0) {
        optNames.push(opt.mName + " x " + opt.qty)
        optAmt += opt.qty * opt.amount
      }
      return null
    })
  }

  const optTxt = optNames.join(", ")
  const itemAmt = parseInt(order.amount) + parseInt(optAmt)
  return { itemAmt, itemTxt: item.itemName, optTxt }
}


/** @UNIONPOS */

function transformOrdersUnionpos({ shopInfo, orders, tableList, itemList, itemOptions, prePay = false }) {
  let newOrders = []

  orders.map((order) => {
    let orderData = JSON.parse(order.orderData);
    order.orderData = orderData

    if (prePay && isJson(order.payData)) order.payDataObj = JSON.parse(order.payData);

    let tables = tableList.filter(table => table.TableCode === parseInt(orderData.TableCode))

    const newOrder = {
      ...order,
      time: order.created,
      // orderInfoHtml: _getHtmlUnionpos(orderData.OrderData, itemList),
      orderInfoHtml: getHtmlUnionpos({ shopInfo, orderData, itemList, itemOptions }),
      payInfo: prePay ? _getPayInfoUnionpos(order) : "",
    }
    if (tables.length === 1) {
      newOrder.TableName = tables[0].TableName
    } else {
      console.log(orderData)
      newOrder.TableName = orderData.TableCode + " - 삭제된 테이블"
    }

    // 
    // payData 이면 orderStatus===true 일때만 주문성공임
    // 
    if (prePay) {
      if (order.isOrdered && order.isPaid) newOrders.push(newOrder)
    }
    else newOrders.push(newOrder)
    return null
  });
  return newOrders
}

const _getPayInfoUnionpos = (order) => {
  let info = ""
  let payData = {}
  if (order.payData && isJson(order.payData)) {
    payData = JSON.parse(order.payData)
    order.payData = payData

    if (order.isPaid) info += "결제완료 - "
    info += `${kiloComma(order.orderData?.orderAmt)}원`

    if (order.isPayCanceled) info += " - 결제취소"
  }

  return info
}

function getHtmlUnionpos({ shopInfo, orderData, itemList, itemOptions }) {

  const OrderData = orderData.OrderData

  let htmlTxt = `<div>`
  OrderData.map(order => {

    if (debug) console.log('*'.repeat(100))
    if (debug) console.log('order', order)

    const idx = itemList.findIndex(item => item.itemCode === order.ItemCode)
    if (idx >= 0) {

      // const { itemAmt, itemTxt, optTxt } = _getItemInfo({ shopInfo, order, item: itemList[idx], itemList, itemOptions })
      const { itemAmt, itemTxt, optTxt } = getOrderItemInfoUnionpos({ shopInfo, order, item: itemList[idx], itemList, itemOptions })

      htmlTxt += `  <div style="display: flex; justify-content: space-between; margin-bottom: 3px">`
      htmlTxt += `    <div style="flex: 1">`
      // htmlTxt += `      <p style="margin: 0px">${order.Qty} x ${orderTxt} </p>`
      htmlTxt += `      <p style="margin: 0px">${order.Qty} x ${itemTxt} </p>`
      if (optTxt) {
        htmlTxt += `    <p style="margin: 0px">(${optTxt})</p>`
      }
      htmlTxt += `    </div>`
      htmlTxt += `    <p style="margin: 0px; font-weight: bold">${kiloComma(itemAmt)} 원</p>`
      htmlTxt += `  </div>`

    } else {
      htmlTxt += `<div><p style="margin: 0px">Menu Item Not Found</p></div>`
    }

    return null
  })
  htmlTxt += `</div>`
  return htmlTxt
}

export const getOrderItemInfoUnionpos = ({ shopInfo, order, item, itemList, itemOptions }) => {
  const itemObj = createItemObj({ shopInfo, item, itemList, itemOptions2: itemOptions })

  if (debug) console.log('itemName', item.itemName)
  if (debug) console.log('itemObj', itemObj)

  const setItemList = [] // 고정 변동 공통
  if (item.menuType2 === "FIXED" || item.menuType2 === "DYNAMIC") {
    itemObj.SetMenuItemList?.map(setItem => {

      /** @변동세트 */
      if (item.menuType2 === "DYNAMIC") {
        setItem.ChoiceMenuList?.map(cMenu => {
          if (cMenu.itemList) {
            cMenu.itemName = cMenu.itemList[0]?.itemName || ""
            setItemList.push(cMenu)
          } else setItemList.push({})
          return null
        })
      }

      /** @고정세트 */
      else {
        setItemList.push(setItem)
      }
      return null
    })
  }

  if (debug) console.log('setItemList', setItemList)

  let optAmt = 0
  const names = []
  if (order.SetMenu) {
    if (debug) console.log('order.SetMenu', order.SetMenu)
    order.SetMenu.map(sMenu => {
      const idx = setItemList.findIndex(s => s.ItemCode === sMenu.SetCode)
      if (idx >= 0) {
        const setItem = setItemList[idx]
        names.push(setItem.itemName)
        if (item.menuType2 === "DYNAMIC") {
          optAmt += parseInt(sMenu.SetQty) * parseInt(setItem.Price)
        }
      } else {
        names.push("Option Item Not Found")
      }
      return null
    })
  }
  const optTxt = names.join(", ")
  const itemAmt = parseInt(order.Qty) * (parseInt(item.price) + optAmt)

  return { itemAmt, itemTxt: item.itemName, optTxt }
}


/** @OKPOS */

function transformOrdersOkpos({ orders, tableList, itemList }) {
  let newOrders = orders.map((order) => {
    let orderData = JSON.parse(order.orderData);
    let tables = tableList.filter(table => table.TABLE_CD === orderData.orderData.TABLE_CD)

    if (tables.length === 1) {
      return {
        TableName: tables[0].TABLE_NM,
        time: order.lastModified,
        orderInfoHtml: _getHtmlOkpos(orderData.orderData.ORDER_INFO, itemList),
      }
    } else {
      return {
        TableName: orderData.orderData.TABLE_CD + " 삭제된 테이블",
        time: order.lastModified,
        orderInfoHtml: _getHtmlOkpos(orderData.orderData.ORDER_INFO, itemList),
      }
    }

  });
  return newOrders
}

const _getHtmlOkpos = (orderList, itemList) => {

  let htmlTxt = `<div>`
  orderList.map(order => {
    const idx = itemList.findIndex(item => item.itemCode === order.PROD_CD)
    if (idx >= 0) {
      const itemName = itemList[idx].itemName
      const prc = order.PROD_AMT || 0
      const totAmt = parseInt(prc) * parseInt(order.PROD_CNT)
      htmlTxt += `<div style="display: flex; justify-content: space-between; width: 100%">`
      htmlTxt += `  <p style="margin: 0px">${order.ORDER_TYPE}</p>`
      htmlTxt += `  <div style="display: flex; justify-content: space-between; width: 80%">`
      htmlTxt += `    <p style="margin: 0px">${order.PROD_CNT} x ${itemName} </p>`
      htmlTxt += `    <p style="margin: 0px">${kiloComma(totAmt)}원</p>`
      htmlTxt += `  </div>`
      htmlTxt += `</div>`
    } else {
      htmlTxt += `<div><p style="margin: 0px">Menu Item Not Found</p></div>`
    }
    return null
  })
  htmlTxt += `</div>`
  return htmlTxt
}


/** @Common */

function getItemLineHtml({ qty, itemTxt, optTxt, itemAmt }) {

  let htmlTxt = ""
  htmlTxt += `  <div style="display: flex; justify-content: space-between; margin-bottom: 3px">`
  htmlTxt += `    <div style="flex: 1">`
  htmlTxt += `      <p style="margin: 0px">${qty} x ${itemTxt} </p>`
  if (optTxt) {
    htmlTxt += `    <p style="margin: 0px">(${optTxt})</p>`
  }
  htmlTxt += `    </div>`
  htmlTxt += `    <p style="margin: 0px; font-weight: bold">${kiloComma(itemAmt)} 원</p>`
  htmlTxt += `  </div>`

  return htmlTxt
}
