import React, { useEffect, useState } from "react";
import { fileUpload } from "../../../lib/apiLib";
// import { shopItemUpdate, okposItemUpdate } from "../../../lib/ec2-api-lib";
import { updateItem } from "../../../lib-pos";
import { imageCompress, isJson, getObjFromArray } from "../../../lib/utils";
// import PostFormSection from "../../../components/PostFormSection";
import { FormInput, ModalForm, Button, useStyles, FormBox } from "../../../components";
// import { unionposShopItemUpdate } from "../../../lib-pos/unionpos/menu-db";
import { debug } from "../../../settings";
import { targetLangs } from "../../../settings/strings";
import { useInputFields } from "../../../lib/hooksLib";

const str = {
  updateSuccess: "성공적으로 저장하였습니다!"
}

export default function ModalOptionGroup(props) {
  const {
    shopInfo,
    item,
    itemOptions2,
    visible,
    setIsVisible,
    onload,
  } = props;
  const title = `"${item.itemName}" 옵션 메뉴 추가 | 삭제`;
  const { gStyle } = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const _selectList = ["선택 안함"]

  let defaultFields = { name: _selectList[0] }

  if (shopInfo.posName === "OKPOS") {
    const obj = getObjFromArray("groupCode", item.optionGroupCode, itemOptions2.optionGroupList)
    if (obj) defaultFields.name = obj.name
  }

  if (shopInfo.posName === "DUMMY_POS") {
    const obj = getObjFromArray("optionGroupCode", item.optionGroupCode, itemOptions2.optionGroupList)
    if (obj) defaultFields.name = obj.optionGroupName
  }

  if (debug) console.log("item.optionGroupCode", item.optionGroupCode)
  if (debug) console.log("defaultFields", defaultFields)

  const [selectList, setSelectList] = useState(_selectList)
  const [fields, setFields] = useInputFields(defaultFields)

  useEffect(() => {
    function start() {
      try {

        if (debug) console.table(itemOptions2.optionGroupList)

        if (shopInfo.posName === "OKPOS") {
          if (debug) console.log("item", item)
          if (debug) console.log("item.SIDE_MENU_YN", item.SIDE_MENU_YN)

          if (item["SIDE_MENU_YN"] === "Y") throw Error("사이드메뉴가 이미 등록되어 있습니다!")

          const names = itemOptions2.optionGroupList.map(o => o.name)
          const selList = [..._selectList, ...names]
          if (debug) console.log('selectList', selList)
          setSelectList(selList)
        }

        if (shopInfo.posName === "DUMMY_POS") {
          const names = itemOptions2.optionGroupList.map(o => o.optionGroupName)
          const selList = [..._selectList, ...names]
          setSelectList(selList)
        }

      } catch (e) {
        alert(e.message)
        setIsVisible(false)
      }
    }
    start()
  }, [itemOptions2])


  const onSubmit = async () => {
    try {
      setIsLoading(true);

      console.log('fields', fields)

      let body = {}

      /** 
       * OKPOS optionGroup { groupCode, name, classList }
       */
      if (shopInfo.posName === "OKPOS") {
        const obj = getObjFromArray("name", fields.name, itemOptions2.optionGroupList)
        const selGroupCode = obj ? obj.groupCode : ""
        body = { optionGroupCode: selGroupCode }
      } 
      
      /** 
       * DUMMY_POS optionGroup { optionGroupCode, optionGroupName, optionClassCodes }
       */
      else if (shopInfo.posName === "DUMMY_POS") {
        const obj = getObjFromArray("optionGroupName", fields.name, itemOptions2.optionGroupList)
        const selGroupCode = obj ? obj.optionGroupCode : ""
        body = { optionGroupCode: selGroupCode }
      } 
      
      else {
        throw Error("INVALID POSNAME")
      }

      let res = updateItem({ shopInfo, id: item.id, body })

      if (res.err) throw Error(res.err.message)
      alert(str.updateSuccess)
      await onload()
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setIsVisible(false);
    }
  };

  const styles = {
    text: { ...gStyle.text, marginBottom: "3px" },
    formGroup: { margin: 0, padding: 5 }
  };

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setIsVisible}
      title={title}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <div style={styles.formGroup}>
        <FormInput
          style={{ marginBottom: 0 }}
          label="옵션 그룹 추가"
          name="name"
          value={fields.name}
          onChange={setFields}
          inputType="select"
          selectList={selectList}
        />
      </div>
    </ModalForm>
  )
}
