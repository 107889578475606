import React, { useEffect, useState } from "react";
import { CardContainer, useStyles, CustomTable, Button } from "../../../components";
import { CustomTable2 } from "../../../components/Tables2";
import { debug } from "../../../settings";
import { updateItemOption, getPosNoList } from "../../../lib-pos";

const tableHeaders = {
  "OKPOS": [
    { name: "TABLE_GR_CD", displayName: "POS 테이블그룹코드" },
    { name: "TABLE_GR_NM", displayName: "POS 테이블그룹명" },
    { name: "TABLE_CD", displayName: "POS 테이블코드" },
    { name: "TableNo", displayName: "ONL 테이블번호" },
    { name: "TABLE_NM", displayName: "POS 테이블명" },
    { name: "TableName", displayName: "ONL 테이블명" },
    { name: "FLOOR_NO", displayName: "POS 층번호", type: "input" },
  ],
  "UNIONPOS": [
    { name: "FloorNum", displayName: "POS 층번호" },
    { name: "TableNo", displayName: "ONL 테이블번호" },
    { name: "TableName", displayName: "ONL 테이블명" },
    { name: "PosNo", displayName: "포스 번호", type: "input" },
  ],
  "OASIS_POS": [
    { name: "tableGroup", displayName: "테이블 그룹" },
    { name: "tableGroupName", displayName: "테이블 그룹명" },
    { name: "tableNo", displayName: "테이블번호" },
    { name: "tableName", displayName: "테이블명" },
  ],
  "EASY_POS": [
    { name: "TABLE_GROUP_NAME", displayName: "TABLE_GROUP_NAME" },
    { name: "TABLE_GROUP_CODE", displayName: "TABLE_GROUP_CODE" },
    { name: "tableCode", displayName: "TABLE_CODE" },
    { name: "TABLE_DIS_NO", displayName: "TABLE_DIS_NO" },
    { name: "tableNo", displayName: "tableNo" },
    { name: "tableName", displayName: "tableName" },
  ],
}

export default function PosTableList(props) {
  const { shopInfo, onload, setIsLoading, itemOptions3 } = props

  const { gStyle, } = useStyles();
  const title = (
    shopInfo.posName === "OASIS_POS"
    || shopInfo.posName === "EASY_POS"
  ) ? "테이블 정보" : "포스 테이블 추가 세팅";
  // const tableHeader = tableHeaders[shopInfo.posName]
  // console.log(tableHeader)
  const [tableHeader, setTableHeader] = useState([])
  // const [tableList, setTableList] = useState([])
  const [rowItems, setRowItems] = useState([])

  // 
  // unionPOS 용
  // 
  const [infoText, setInfoText] = useState("")

  useEffect(() => {
    const start = () => {
      setTableHeader(tableHeaders[shopInfo.posName])
      if (shopInfo.posName === "UNIONPOS") unionpos()

      if (shopInfo.posName === "OKPOS") okpos()

      if (shopInfo.posName === "OASIS_POS") {
        // setTableList(itemOptions3.tableList)
        setRowItems(itemOptions3.tableList)
      }

      if (shopInfo.posName === "EASY_POS") {
        const newTables = itemOptions3.tableList.map(obj => {
          const tGrps = itemOptions3.tableGroupList.filter(g => g.TABLE_GROUP_CODE === obj.TABLE_GROUP_CODE)
          if (tGrps.length > 0) return { ...obj, ...tGrps[0] }
          else return obj
        })
        setRowItems(newTables)
      }
    }
    start();
  }, [itemOptions3])

  // const oasispos = () => {
  //   setTableList(itemOptions3.tableList)
  // }

  const unionpos = async () => {
    try {
      setIsLoading(true)
      let res = await getPosNoList({
        shopInfo,
        apiName: "posList",
        body: { StoreCode: shopInfo.storeCode }
      })
      if (res.err) throw Error(res.err.message)

      const posNoList = res.result.DATA?.PosList
      const arr = posNoList.map(o => o.PosNo)
      const txt = `포스번호 리스트 : [ ${arr.join(", ")} ]`
      setInfoText(txt)

      const _tableList = itemOptions3.TableList || []
      const newTableList = _tableList.map(tbl => {
        const name = "PosNo"
        if (tbl[name]) return tbl
        else return {
          ...tbl,
          [name]: arr[0] // uncontrolled Error 떄문에 반드시 정의해야 함
        }
      })
      if (debug) console.table(newTableList)
      setRowItems(newTableList)
      // setTableList(newTableList)
    } catch (e) {
      if (debug) console.log(e)
      alert(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const okpos = () => {
    const _tableList = itemOptions3.TableList || []
    if (debug) logTableListOkpos(_tableList)
    const newTableList = _tableList.map(tbl => {
      const name = "FLOOR_NO"
      if (tbl[name]) return tbl
      else return {
        ...tbl,
        [name]: "00" // uncontrolled Error 떄문에 반드시 정의해야 함
      }
    })
    setInfoText("층 번호를 클릭하여 편집한 후 저장하세요!")
    setRowItems(newTableList)
    // setTableList(newTableList)
  }

  const onChangeItem = (name, value, item) => {
    // setTableList(preList => {
    setRowItems(preList => {
      const newList = preList.map(tbl => {
        if (tbl.TableNo === item.TableNo) {
          return {
            ...tbl,
            [name]: value
          }
        } else return tbl
      })
      return newList
    })
  }

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      const body = {
        TableList: JSON.stringify(rowItems)
      }
      let res = await updateItemOption({ shopInfo, body })
      if (res.err) throw Error(res.err.message)

      await onload()
    } catch (e) {
      if (debug) console.log(e)
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  const styles = {
    subTitleBar: { margin: 5, },
    subTitle: { ...gStyle.subtitle, marginBottom: 5 },
  }

  return (
    <CardContainer
      title={title}
      onClickBack={() => props.history.goBack()}
    >
      <div style={styles.subTitleBar}>
        {/* <p style={{ ...styles.subTitle, textAlign: "center" }}>테이블 정보</p> */}
        {infoText !== "" &&
          <p style={styles.subTitle}>{infoText}</p>
        }
      </div>

      <CustomTable2
        tableHeader={tableHeader}
        rowItems={rowItems}
        setRowItems={setRowItems}
        onChangeItem={onChangeItem}
      />


      {
        shopInfo.posName === "UNIONPOS" &&
        <>
          <br />
          <Button
            size="sm"
            variant="primary"
            onClick={onSubmit}
            width="100%"
          >저장</Button>
        </>
      }
    </CardContainer>
  )
}

function logTableListOkpos(tableList) {
  const _tableList = tableList.map((table) => {
    let msg = ''
    if (table.TABLE_CD !== table.TableCode) msg += ' WRONG TableCode'
    if (table.TABLE_CD !== table.TableNo) msg += ' WRONG TableNo'
    if (table.TABLE_NM !== table.TableName) msg += ' WRONG TableName'

    return {
      ...table,
      msg,
    }
  })

  console.table(_tableList)
}