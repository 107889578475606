
const posList = ["OKPOS", "DUMMY_POS"]

// export function createNewOptionGroupList({ shopInfo, optionGroupList }) {
//   if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
//   const newGroupList = optionGroupList.map(grp=>{
//     const newGrp = {...grp}
//     delete newGrp.infoHtml
//     delete newGrp.selected
//   })
//   return newGroupList
// }

// export function createNewOptionClassList({ shopInfo, optionClassList }) {
//   if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
//   return {}
// }

// export function createNewOptionItemList({ shopInfo, optionItemList }) {
//   if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
//   return {}
// }

export function createNewOptionList({ shopInfo, optionList }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  const newList = optionList.map(opt=>{
    const newItem = {...opt}
    delete newItem.infoHtml
    delete newItem.selected
    return newItem
  })
  return newList
}