import { easyposProxy, easyposCopyImage } from "./easypos-api-lib";
import {
  shopItemCreate, shopItemUpdate, shopItemDelete,
  shopItemOptionCreate, shopItemOptionGet, shopItemOptionUpdate
} from "../../lib/ec2-api-lib";
import { parseItemOptions } from "../parseItemOptions";
import { sortObjArray2, isJson } from "../../lib/utils";
import {
  getTableGroupList,
  getTableList,
  getTouchClassList,
  mergeTouchKeysAndProducts,
  getShortPosItems,
  createMenuItem, updateMenuItem
} from "./easypos-utils";
import { debug } from "../../settings";

const Texts = {
  noItemFound: "NO ITEM FOUND",
  alertFail: "정보를 가져오는 데 실패했습니다!",
  alertFailUpdate: "정보를 수정하는 데 실패했습니다!",
}

export async function easyposMenuUpdate({ shopInfo, itemList, setComment }) {
  try {

    let preOptions = {};
    let isNew = true;

    let res = await shopItemOptionGet({
      shopId: shopInfo.shopId,
      optionName: shopInfo.posName,
    });

    // 
    // 신규이면 preOptions.isNew == true
    // 
    if (res.err) {
      if (res.err.message !== Texts.noItemFound) throw new Error(Texts.alertFail + " at itemOptionGet EASY_POS");
    } else {
      preOptions = parseItemOptions({ shopInfo, itemOptions: res.result?.Item });
      isNew = false;
    }

    /**
     * @TODO storeCode=shopNo subInfo: {masterDataKey, posNo, headOfficeNo}
     */
    // const proxyReqBody2 = {
    //   shopNo: shopInfo.storeCode,
    //   ...shopInfo.subInfo // {masterDataKey, posNo, headOfficeNo}
    // }
    const proxyReqBody = {
      "storeCode": shopInfo.storeCode,
      masterDataKey: shopInfo.easypos_masterDataKey,
      headOfficeNo: shopInfo.easypos_headOfficeNo,
      posNo: shopInfo.easypos_posNo,
    }

    let resp = {}

    const commonProps = { shopInfo, itemList, preOptions, isNew, setComment, proxyReqBody }

    resp = await updateTableList(commonProps);
    if (resp.err) throw Error(resp.err.message);

    resp = await updateTouchClassList(commonProps);
    if (resp.err) throw Error(resp.err.message);

    const respItems = await cleanUpItemList(commonProps);
    if (respItems.err) throw Error(respItems.err.message);

    resp = await updateSetMenuList({ ...commonProps, productList: respItems.productList });
    if (resp.err) throw Error(resp.err.message);

    resp = await savePosItems({
      ...commonProps,
      posItems: respItems.posItems,
      serverItems: respItems.serverItems
    });
    if (resp.err) throw Error(resp.err.message);

    // console.log('newServerItems', newServerItems)

    return { result: { message: "Menu Updated Successfully!" } }
  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } };
  }
}

async function updateTableList(props) {
  try {
    props.setComment(`Updating Table Info ...`)
    const body = props.proxyReqBody

    let res = {}
    res = await easyposProxy({ apiName: "tableGroup", body })

    if (debug) console.log('res', res)

    if (res.err) throw Error(res.err.message)
    let tableGroupList = res.result.Items
    tableGroupList = getTableGroupList(tableGroupList)

    res = await easyposProxy({ apiName: "table", body })
    if (res.err) throw Error(res.err.message)
    let tableList = res.result.Items
    tableList = getTableList(tableList)

    if (debug) {
      console.log("=".repeat(50), "tableGroupList")
      console.table(tableGroupList)
      console.log("=".repeat(50), "tableList")
      console.table(tableList)
    }

    const params = {
      shopId: props.shopInfo.shopId,
      optionName: props.shopInfo.posName,
      body: {
        tableGroupList: JSON.stringify(tableGroupList),
        tableList: JSON.stringify(tableList)
      }
    };

    if (props.isNew) res = await shopItemOptionCreate(params);
    else res = await shopItemOptionUpdate(params);
    if (res.err) throw new Error(Texts.alertFailUpdate + " - tableList");

    return {
      tableGroupList,
      tableList
    };
  } catch (e) {
    if (debug) console.log('updateTableList', e)
    return { err: { message: e.message } };
  }
}


async function updateTouchClassList(props) {
  try {
    props.setComment(`Updating TouchClass Info ...`)

    const touchClassList_pre = props.preOptions.touchClassList

    // console.table("touchClassList_pre", touchClassList_pre)

    let res = {}
    res = await easyposProxy({ apiName: "touchClass", body: props.proxyReqBody })
    if (res.err) throw Error(res.err.message)
    let arr = res.result.Items
    arr = getTouchClassList(arr)

    const newTouchClassList = arr.map(obj => {
      let newObj = {};
      let obj_pres = touchClassList_pre.filter(pre => pre.TOUCH_CLASS_CODE === obj.TOUCH_CLASS_CODE)

      if (obj_pres.length === 1) {
        newObj = {
          ...obj_pres[0],
          displayName: (obj_pres[0].TOUCH_CLASS_NAME === obj.TOUCH_CLASS_NAME)
            ? obj_pres[0].displayName
            : obj.TOUCH_CLASS_NAME,
        }
      } else {
        newObj = {
          ...obj,
          groupCode: obj.TOUCH_CLASS_CODE,
          grouopName: obj.TOUCH_CLASS_NAME,
          displayName: obj.TOUCH_CLASS_NAME,
          sortOrder: parseInt(obj["SEQ"]),
          nameLang: {},
          hidden: false
        }
      }

      return newObj
    })

    const newTuClsList = sortObjArray2({ arr: newTouchClassList, key: "sortOrder" })

    const params = {
      shopId: props.shopInfo.shopId,
      optionName: props.shopInfo.posName,
      body: {
        touchClassList: JSON.stringify(newTuClsList),
      }
    };

    res = await shopItemOptionUpdate(params);
    if (res.err) throw new Error(Texts.alertFailUpdate + " - touchClassList");

    return { touchClassList: newTuClsList };
  } catch (e) {
    return { err: { message: e.message } };
  }
}


async function updateSetMenuList(props) {
  try {
    // 
    // easypostProxy의 apiName 기준
    // 
    const setOptions = {
      // subItem: [],   // 필요하지 않음
      itemOrderClass: [],
      orderClass: [],
      order: [],
    }

    const names = Object.keys(setOptions)

    let errMsg = ""
    for (let i = 0; i < names.length; i++) {

      props.setComment(`Updating ${names[i]} info ...`)

      let res = await easyposProxy({ apiName: names[i], body: props.proxyReqBody })
      if (res.err) {
        errMsg += res.err.message
        break;
      }

      setOptions[names[i]] = res.result.Items

      if (names[i] === "order") {
        let newOrderList = createNewOrderList({
          preOrderList: props.preOptions.orderList,
          newOrderList: res.result.Items
        })
        // console.table(newOrderList)
        setOptions[names[i]] = newOrderList
      }
    }

    if (errMsg !== "") throw Error(errMsg)

    // console.table(setOptions.order)

    const resNewOrder = addItemNameToOrderList({ orderList: setOptions.order, productList: props.productList })
    if (resNewOrder.err) throw Error(resNewOrder.err.message)

    setOptions.order = resNewOrder.newOrderList
    if (debug) console.table(setOptions.order)

    // 
    // 저장할 option의 항목명 변경
    // 
    const optionBody = {}
    names.map(n => {
      optionBody[n + "List"] = JSON.stringify(setOptions[n])
      return null
    })

    const params = {
      shopId: props.shopInfo.shopId,
      optionName: props.shopInfo.posName,
      body: optionBody
    };

    let res = await shopItemOptionUpdate(params);
    if (res.err) throw new Error(Texts.alertFailUpdate + " - optionMenuInfo");

    return {
      // touchClassList: newTuClsList
    };
  } catch (e) {
    return { err: { message: e.message } };
  }
}


async function cleanUpItemList(props) {
  try {

    props.setComment(`Cleaning Up ItemList ...`)

    let res = {}
    res = await easyposProxy({ apiName: "touchKey", body: props.proxyReqBody })
    if (res.err) throw Error(res.err.message)

    let touchKeyList = res.result.Items


    res = await easyposProxy({ apiName: "product", body: props.proxyReqBody })
    if (res.err) throw Error(res.err.message)

    let productList = res.result.Items

    const posItems = mergeTouchKeysAndProducts(touchKeyList, productList)

    
    if(debug) {
      console.log('='.repeat(50), "items info")
      console.log('touchKeyList', touchKeyList.length, touchKeyList)
      console.log('posItems', posItems.length)
      console.log('productList', productList.length)
      let shortPosItems = getShortPosItems(posItems)
      console.table(shortPosItems)
    }

    let errMsg = ""
    let serverItems = [];

    for (let i = 0; i < props.itemList.length; i++) {
      const serverItem = props.itemList[i]
      const index = posItems.findIndex(posItem => posItem["ITEM_CODE"] === serverItem.itemCode);

      //
      // 포스에 없는 항목 오늘 메뉴DB에서 삭제
      //
      if (index < 0) {
        let res = await shopItemDelete({ shopId: props.shopInfo.shopId, id: serverItem.id });
        if (res.err) {
          errMsg = "필요없는 메뉴아이템 삭제 중 에러가 발생했습니다. " + res.err.message;
          break;
        }
      } else {
        serverItems.push(serverItem);
      }
    }

    if (errMsg !== "") throw Error(errMsg)

    return { posItems, serverItems, productList }
  } catch (e) {
    // console.log(e)
    return { err: { message: e.message } };
  }
}


async function savePosItems({ shopInfo, posItems, serverItems, setComment, preOptions }) {
  try {

    let menuSettings = {};
    if (isJson(preOptions.menuSettings)) menuSettings = JSON.parse(preOptions.menuSettings);

    const newServerItems = []
    let errMsg = ""

    for (let idx = 0; idx < posItems.length; idx++) {

      const posItem = posItems[idx]
      setComment(`Updating... Item ${posItem["ITEM_CODE"]}`)

      const index = serverItems.findIndex((serverItem) => serverItem.itemCode === posItem.ITEM_CODE);

      let res = {};
      let itembody = {};

      /** itemCode가 없으면 신규 아이템이므로 create 함 */
      if (index < 0) {
        itembody = createMenuItem({ posItem, idx });

        itembody = await copyImageToS3IfImageExist({
          shopInfo, itembody, menuSettings,
          oriImage: posItem["ITEM_IMG_URL"]
        });

        res = await shopItemCreate({ shopId: shopInfo.shopId, body: itembody });
      }

      /** 동일한 itemCode가 있으면 update 함 */
      else {
        itembody = updateMenuItem({ posItem, idx, serverItem: serverItems[index] });

        itembody = await copyImageToS3IfImageExist({
          shopInfo, itembody, menuSettings,
          oriImage: posItem["ITEM_IMG_URL"]
        });


        res = await shopItemUpdate({ shopId: shopInfo.shopId, id: serverItems[index].id, body: itembody });
      }
      if (res.err) {
        errMsg += res.err.message
        break;
      }
      newServerItems.push(itembody);
    }
    if (errMsg !== "") throw Error(errMsg)

    return { newServerItems };
  } catch (e) {
    return { err: { message: e.message } }
  }
}


async function copyImageToS3IfImageExist({ shopInfo, itembody, menuSettings, oriImage }) {

  // if (debug) {
  //   if(itembody.oriImage) console.log(itembody.oriImage)
  //   if(itembody.image) console.log(itembody.image)
  // }

  /**
   * @info_server_image가_없으면_수행함
   *  case 1: create pos oriImage O, server image X   -> 수행
   *  case 2: update pos oriImage O, server image X   -> 수행
   *  case 3: update pos oriImage O, server image O
   *    case 3.1 menuSettings.posImageFirst = true                 -> 수행
   *    case 3.2 menuSettings.posImageFirst = false or undefined   -> 수행하지 않음
   * 
   * @case3_필요하면_옵션을_추가하여_해결할_것
   */

  // 
  // case 제외: 포스 이미지가 없으면 리턴
  // 
  if (oriImage === undefined || oriImage === "") {
    if (debug) console.log(itembody.itemCode, "no Image")
    return itembody;
  }

  // 
  // case3.2 서버 이미지가 있으나, 포스 이미지 우선이 아니면 리턴, 즉 서버 이미지 우선이 기본이다.
  // 
  // if (itembody.image !== undefined && itembody.image !== "") {
  if (itembody.image) {
    if (!menuSettings.posImageFirst) {
      if (debug) console.log(itembody.itemCode, "image exist and posImageFirst undefined or false");
      return itembody;
    }
  }

  // 
  // case 1 | case 2 | case 3.1
  // 
  if (debug) console.log(itembody.itemCode, "oriImage", oriImage)

  const res = await easyposCopyImage({ body: { shopId: shopInfo.shopId, imageUrl: oriImage } });
  if (res.status) itembody.image = res.result?.image;
  if (debug) console.log('res copyImage', res.result?.image);

  return itembody;
}

function addItemNameToOrderList({ orderList, productList }) {
  try {

    const newOrderList = orderList.map((order, i) => {
      const idx = productList.findIndex(obj => obj.ITEM_CODE === order.ITEM_CODE)
      let newOrder = {}
      if (idx < 0) newOrder = { ...order, ITEM_NAME: "NO NAME" }
      else newOrder = {
        ...order,
        ITEM_NAME: productList[idx].ITEM_NAME,
      }
      return newOrder
    })

    return { newOrderList }
  } catch (e) {
    return { err: { message: e.message } }
  }
}

function createNewOrderList({ preOrderList, newOrderList }) {

  const newOrders = newOrderList.map(newOrder => {
    const preOrders = preOrderList.filter(preOrder => {
      return (
        preOrder.ORDER_CLASS_CODE === newOrder.ORDER_CLASS_CODE &&
        preOrder.ITEM_CODE === newOrder.ITEM_CODE
      )
    })
    if (preOrders.length === 1) {
      return {
        ...newOrder,
        isSoldout: (preOrders[0].isSoldout === undefined) ? false : preOrders[0].isSoldout
      }
    } else {
      return {
        ...newOrder,
        isSoldout: false
      }
    }
  })

  return newOrders
}
