import React, { useState } from "react";
import { FormInput, ModalForm, Button, useStyles } from "../../../components";
import { useInputFields } from "../../../lib/hooksLib";
import { updateItemOption } from "../../../lib-pos";
import { shopItemCreate } from "../../../lib/ec2-api-lib";
import { getNewCodeWithPrefix, hasSameName } from "../../../lib/utils";
import { debug } from "../../../settings";

export function OnlMenuModal(props) {

  const title0 = props.type === "item" ? "Menu Item" : "Group"
  const title1 = props.type === "item" ? (props.editItem?.itemName || "") : (props.editItem?.groupName || "")
  const title2 = props.isEdit ? "편집" : "생성"

  const title = `${title0} ${title1} ${title2}`;
  // const inputFields = unionposShopItemUpdate.inputFields;
  const [isLoading, setIsLoading] = useState(false);

  const otherProps = { ...props, isLoading, setIsLoading }

  return (
    <ModalForm
      title={title}
      visible={props.visible}
      setIsVisible={props.setVisible}
      isLoading={isLoading}
    >
      {props.type === "group" && <GroupAdd {...otherProps} />}
      {props.type === "item" && <ItemAdd {...otherProps} />}
    </ModalForm>
  )
}


function GroupAdd(props) {
  const gCode = props.isEdit
    ? props.editItem?.groupCode
    : getNewCodeWithPrefix(props.itemOptions2.groupList, "groupCode", 3, "C")

  const [fields, setFields] = useInputFields({
    groupCode: gCode,
    groupName: "",
  })

  const onSubmit = async () => {
    // console.log('='.repeat(50), "GroupAdd, isEdit?", props.isEdit)
    // console.log('itemOptions2', props.itemOptions2)
    // console.table(props.itemOptions2.groupList)

    try {
      if (props.isEdit) throw Error("추가 기능만 가능합니다.")

      if (fields.groupName === "") throw Error("Group Name은 필수 입니다.")

      const group = {
        ...fields,
        displayName: fields.groupName,
        isHidden: false,
        sortOrder: props.itemOptions2.groupList.length
      }
      const newGroupList = [...props.itemOptions2.groupList]

      if (hasSameName({ itemObj: fields, arr: newGroupList, key: "groupName" })) {
        throw Error("동일한 이름이 이미 존재합니다.")
      }

      newGroupList.push(group)

      const body = { groupList: JSON.stringify(newGroupList) }

      let res = await updateItemOption({ shopInfo: props.shopInfo, body })
      if (res.err) throw Error(res.err.message)

      await props.onload()
    } catch (e) {
      alert(e.message)
    } finally {
      props.setVisible(false)
    }
  }

  return (
    <div>
      <FormInput
        label="Group Code"
        name="groupCode"
        value={fields.groupCode}
        onChange={setFields}
        readOnly={true}
      />
      <FormInput
        label="Group Name"
        name="groupName"
        value={fields.groupName}
        onChange={setFields}
      />

      <hr />
      <Button
        title="확인"
        size="sm"
        variant="primary"
        width="100%"
        onClick={onSubmit}
      />
    </div>
  )
}


function ItemAdd(props) {
  const newCode = props.isEdit
    ? props.editItem?.itemCode
    : getNewCodeWithPrefix(props.itemList, "itemCode", 4, "C")

  const [fields, setFields] = useInputFields({
    itemCode: newCode,
    itemName: debug ? "탕수육" : "",
    price: debug ? 10000 : 0,

    // default values
    image: "",
    isHidden: false,
    isSoldout: false,
    isNew: false,
    isHot: false,
    isBest: false,
    sortOrder: 0,
  })

  // console.log(props.selectedGroup2)
  // console.log(props.shopInfo)
  // console.table(props.itemOptions2.groupList)

  const onSubmit = async () => {
    try {
      if (fields.itemCode === "") throw Error("Item Code는 필수항목 입니다!")
      if (fields.itemName === "") throw Error("Item Name은 필수항목 입니다!")
      // if (fields.itemCode === "") throw Error("Item Code는 필수입니다!")

      const newItem = {
        groupCode: props.selectedGroup2.groupCode,
        ...fields,
        itemName2: fields.itemName
      }

      if (hasSameName({ itemObj: newItem, arr: props.itemList, key: "itemName" })) {
        throw Error("동일한 이름이 이미 존재합니다.")
      }

      let res = await shopItemCreate({ shopId: props.shopInfo.shopId, body: newItem });
      if (res.err) throw Error(res.err.message)
      await props.onload()
    } catch (e) {
      alert(e.message)
    } finally {
      props.setVisible(false)
    }
  }

  return (
    <div>
      <FormInput
        label="Item Code"
        name="itemCode"
        value={fields.itemCode}
        onChange={setFields}
        readOnly={true}
      />

      <FormInput
        label="Item Name"
        name="itemName"
        value={fields.itemName}
        onChange={setFields}
      />

      <FormInput
        label="Price"
        name="price"
        value={fields.price}
        type="number"
        onChange={setFields}
      />

      <hr />
      <Button
        title="확인"
        size="sm"
        variant="primary"
        width="100%"
        onClick={onSubmit}
      />
    </div>
  )
}
