import { updateUnionposMenu } from "./unionpos/menu-update";
import { okposMenuUpdate } from "./okpos/okpos-menu-update4";
import { dummyposUpdateMenu } from "./dummy-pos/dummypos-menu-update";
import { oasisposUpdateMenu } from "./oasis-pos/oasispos-menu-update";
// import { easyposProxy } from "./easypos/easypos-api-lib";
import { easyposMenuUpdate } from "./easypos/easypos-menu-update2";


const pos = {
  "OKPOS": { downloadPosMenuFn: okposMenuUpdate, },
  "UNIONPOS": { downloadPosMenuFn: updateUnionposMenu, },
  "DUMMY_POS": { downloadPosMenuFn: dummyposUpdateMenu },
  "OASIS_POS": { downloadPosMenuFn: oasisposUpdateMenu },
  "EASY_POS": { downloadPosMenuFn: easyposMenuUpdate },
}

const posList = Object.keys(pos);

/**
 * @param {*} params { shopInfo, itemList, itemOptions, menus }
 * @returns 
 */
export function downloadMenu(params) {
  const shopInfo = params.shopInfo
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  return pos[shopInfo.posName].downloadPosMenuFn(params);
}

export { resetOnlMenu } from "./resetOnlMenu"

export { getItemList } from "./getItemList"

export { getItemOptions } from "./getItemOptions";

export { updateItemOption } from "./updateItemOption"

export { getGroupList } from "./getGroupList"

export { getSelectedGroup } from "./getGroupList"

export { getGroupUpdateBody } from "./getGroupUpdateBody"

export { updateItem } from "./updateItem"

export { getSelectedItems } from "./getSelectedItems"

export { parseItemOptions } from "./parseItemOptions"

export { getSetmenuText } from "./getSetmenuText"

export { testRequestOrder } from "./testRequestOrder"

export { createItemObj } from "./createItemObj"

export { getPosNoList } from "./getPosNoList"

export { transformOrders } from "./transformOrders"

export { getOrderInfo } from "./getOrderInfo"

export { parseShopInfo } from "./parseShopInfo"

export { getGroupName, getItemName, getItemDescription } from "./parseNames"

export {
  // createNewOptionGroupList,
  // createNewOptionClassList,
  // createNewOptionItemList,
  createNewOptionList
} from "./onlOptions"
