import React, { useEffect, useState } from "react";
import { useInputFields } from "../../../lib/hooksLib";
import {
  onlItemOptionCreate,
  onlItemOptionUpdate,
  shopItemOptionUpdate,
  shopItemUpdate,
} from "../../../lib/ec2-api-lib";
import { getNewCode, hasSameName } from "../../../lib/utils";
import OnlOptionModalUi from "./OnlOptionModalUi";
import { removeOptionGroupCode } from "../../../lib-pos/okpos/okpos-utils";
import { getNewCodeWithPrefix } from "../../../lib/utils";
import { createNewOptionList } from "../../../lib-pos";
import { debug } from "../../../settings";

const str = {
  noItemsSelected: "선택된 항목이 없습니다!",
  noName: "명칭이 비어 있습니다!"
}

export default function OnlOptionGroupModal(props) {
  const {
    // visible, // modalUi에서 사용
    setVisible,
    optGroups,
    optClsList,
    shopInfo, posName,
    onload,
    isNewOption,
    isEdit,
    item,         // class or group Object
    tableHeaders,
    itemList3
  } = props;
  const modalTitle = isEdit ? "옵션 그룹 편집" : "옵션 그룹 추가"
  const itemList = itemList3;

  const [isLoading, setIsLoading] = useState(false);
  const [tHeader, setTHeader] = useState([])
  const [tHeaderIncluded, setTHeaderIncluded] = useState([])

  let groupCodeKey = "groupCode"
  let groupNameKey = "name"
  let classCodeKey = "classCode"
  let classListKey = "classList"

  if (posName === "DUMMY_POS") {
    groupCodeKey = "optionGroupCode"
    groupNameKey = "optionGroupName"
    classCodeKey = "optionClassCode"
    classListKey = "optionClassCodes"
  }

  let groupCode = isEdit ? item.groupCode : getNewCode(optGroups, "groupCode", 3)
  let name = isEdit ? item.name : ""

  if (posName === "DUMMY_POS") {
    groupCode = isEdit ? item.optionGroupCode : getNewCodeWithPrefix(optGroups, "optionGroupCode", 3)
    name = isEdit ? item.optionGroupName : ""
  }

  const [fields, setFields] = useInputFields({ groupCode, name }) // ui 에서 정의된 param을 사용해야 함
  const [optItems, setOptItems] = useState([])
  const [includedItems, setIncludedItems] = useState([])

  const [showFullItems, setShowFullItems] = useState(false)

  useEffect(() => {
    function start() {

      if (isEdit) {
        const includedList = []
        item[classListKey].map(code => {
          const items = optClsList.filter(o => o[classCodeKey] === code)
          if (items.length === 1) includedList.push(items[0])
        })
        setIncludedItems(includedList)

        // const tableHeaderIncluded = [...tableHeaders.classes]
        const tableHeaderIncluded = []
        tableHeaders.classes.map(h => {
          if (h.name !== "infoHtml") tableHeaderIncluded.push(h)
          return null
        })
        tableHeaderIncluded.unshift(tableHeaders.sorting)
        setTHeaderIncluded(tableHeaderIncluded)
      }

      // 
      // 추가를 위한 서브 항목 리스트
      // 
      const tableHeader = [...tableHeaders.classes]
      tableHeader.pop() //  아이템 리스트 제거
      tableHeader.unshift(tableHeaders.checkObj)
      setTHeader(tableHeader)

      const newItems = optClsList.map(o => {
        if (isEdit) {
          return { ...o, checked: item[classListKey].includes(o[classCodeKey]) }
        } else return { ...o, checked: false }
      })
      setOptItems(newItems)
    }
    start()
  }, [item, tableHeaders, optClsList])

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const includedClasses = includedItems.map(o => o[classCodeKey])
      const newGrp = {
        [groupCodeKey]: fields.groupCode,
        [groupNameKey]: fields.name,
        [classListKey]: includedClasses
      }

      // 
      // Validation
      // 
      if (newGrp[groupNameKey] === "") throw Error(str.noName)

      let newItems = []

      // 편집
      if (isEdit) {

        const restArr = optGroups.filter(g => g[groupCodeKey] !== fields.groupCode)
        if (hasSameName({ itemObj: newGrp, arr: restArr, key: groupNameKey })) {
          throw Error("동일한 이름이 이미 존재합니다.")
        }

        optGroups.map(obj => {
          delete obj.infoHtml;
          delete obj.selected;
          if (obj[groupCodeKey] === newGrp[groupCodeKey]) newItems.push({ ...obj, ...newGrp })
          else newItems.push(obj)
        })
      }

      // 추가
      else {
        newItems = [...optGroups]

        if (hasSameName({ itemObj: newGrp, arr: newItems, key: groupCodeKey })) {
          throw Error("동일한 코드가 이미 존재합니다.")
        }

        if (hasSameName({ itemObj: newGrp, arr: newItems, key: groupNameKey })) {
          throw Error("동일한 이름이 이미 존재합니다.")
        }

        newItems.push(newGrp)
      }

      newItems = createNewOptionList({ shopInfo, optionList: newItems })

      // console.log("=".repeat(50), "optionGroupList")
      // console.table(newItems)
      // throw Error('test...')

      const params = {
        shopId: shopInfo.shopId,
        body: { optionGroupList: JSON.stringify(newItems) }
      }
      let res = {}

      if (posName === "OKPOS") {
        params.posName = posName
        if (isNewOption) res = await onlItemOptionCreate(params)
        else res = await onlItemOptionUpdate(params)
      } else if (posName === "DUMMY_POS") {
        params.optionName = posName
        res = await shopItemOptionUpdate(params)
      } else {
        throw Error("Not Supported POSNAME")
      }

      if (res.err) throw Error(res.err.message)
      onload()
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const onSubmitDel = async () => {
    try {
      setIsLoading(true);

      const newGroups = [...optGroups]
      const idx = newGroups.findIndex(obj => obj[groupCodeKey] === item[groupCodeKey])
      newGroups.splice(idx, 1)

      // console.log('newGroups', newGroups)

      const params = {
        shopId: shopInfo.shopId,
        posName,              // okpos
        optionName: posName,  // dummypos
        body: { optionGroupList: JSON.stringify(newGroups) }
      }

      let res = {}
      if (posName === "OKPOS") {
        res = await onlItemOptionUpdate(params)
        if (res.err) throw Error(res.err.message)
        res = await removeOptionGroupCode({ itemList, optionGroupCode: item[groupCodeKey] })
        if (res.err) throw Error(res.err.message)
      } else {
        res = await shopItemOptionUpdate(params)
        if (res.err) throw Error(res.err.message)
        res = await removeOptionGroupCodeDummypos({ itemList, optionGroupCode: item[groupCodeKey] })
        if (res.err) throw Error(res.err.message)
      }

      onload()
    } catch (e) {
      console.log(e)
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const onChangeChecked = (checkedItem) => {
    // if (debug) console.log(checkedItem)

    const newItems = optItems.map(item => {
      if (item[classCodeKey] === checkedItem[classCodeKey]) {
        return {
          ...item,
          checked: !checkedItem.checked
        }
      } else return item
    })

    setOptItems(newItems)

    //
    // 해당 아이템 리스트 변경
    // 
    const newIncludedItems = [...includedItems]
    if (!checkedItem.checked) { // 이전 체크 사항을 확인해야 함 
      newIncludedItems.push(checkedItem)
    } else {
      const idx = newIncludedItems.findIndex(o => o[classCodeKey] === checkedItem[classCodeKey])
      newIncludedItems.splice(idx, 1)
    }
    setIncludedItems(newIncludedItems)
  }

  const sorting = (obj, direction) => {
    const newItems = [...includedItems]
    let idx = newItems.findIndex(o => o[classCodeKey] === obj[classCodeKey])
    newItems.splice(idx, 1)
    if (direction === "up") {
      idx = idx === 0 ? idx : --idx
    } else {
      idx = idx === newItems.length ? idx : ++idx
    }
    newItems.splice(idx, 0, obj)
    setIncludedItems(newItems)
  }

  const otherProps = {
    isLoading,
    tHeader, tHeaderIncluded,
    fields, setFields,
    optItems,
    includedItems,
    showFullItems, setShowFullItems,
    onSubmit, onSubmitDel, onChangeChecked, sorting
  }

  return (
    <OnlOptionModalUi
      {...props}
      {...otherProps}
      type="group"
      modalTitle={modalTitle}
    />
  )
}

async function removeOptionGroupCodeDummypos({ itemList, optionGroupCode }) {
  try {
    const items = itemList.filter(o => o.optionGroupCode === optionGroupCode)

    let errMsg = ""
    for (let i = 0; i < items.length; i++) {
      // await Promise.all(itemsWithCode.map(async item => {
      const item = items[i]
      let res = shopItemUpdate({
        shopId: item.shopId,
        id: item.id,
        body: { optionGroupCode: "" }
      })
      if (res.err) {
        errMsg = res.err + " at " + item.itemCode + " update"
        break;
      }
      // }))
    }
    if (errMsg !== "") throw Error(errMsg)

    return {}
  } catch (e) {
    console.log(e)
    return { err: { message: e.message } }
  }
}