import { notSupported } from "../../../../lib-pos/posList"
const pos = {
  "UNIONPOS": { createCartObj: createCartObjUnionpos },
  "OKPOS": { createCartObj: notSupported },
  "OASIS_POS": { createCartObj: createCartObjOasispos },
  "EASY_POS": { createCartObj: createCartObjEasypos },
  "DUMMY_POS": { createCartObj: createCartObjDpos },
}

const posList = Object.keys(pos)

/**
 * @param {*} params {  shopInfo, item, itemList, itemOptions2, selectedOptions, itemCount }
 * @returns 
 */
export function createCartObj({ shopInfo, ...rest }) {
  if (!posList.includes(shopInfo.posName)) return notSupported()
  return pos[shopInfo.posName].createCartObj({ ...rest })
}

function createCartObjUnionpos({
  item, itemList, selectedOptions, itemCount
}) {
  const options = []
  selectedOptions.map(optCls => {
    optCls.optionItems.map(optItem => {
      const items = itemList.filter(o => o.itemCode === optItem.optionItemCode)
      options.push({
        ...items[0],
        ...optItem,
        // optPrice: optItem.optPrice,
        count: optItem.count || 1,  // 나중에 option 수량 선택을 위함
      })
      return null
    })
    return null
  })

  return {
    ...item,
    count: itemCount,
    options
  }
}


function createCartObjOasispos({
  item, itemList, selectedOptions, itemCount
}) {
  const options = []
  selectedOptions.map(optCls => {
    optCls.optionItems.map(optItem => {
      const items = itemList.filter(o => o.itemCode === optItem.optionItemCode)
      if (items.length === 1) {
        options.push({
          ...optItem,
          count: optItem.count || 1,  // 나중에 option 수량 선택을 위함
        })
      }
      return null
    })
    return null
  })

  return {
    ...item,
    count: itemCount,
    options
  }
}


function createCartObjEasypos({ item, selectedOptions, itemCount }) {
  const options = []
  selectedOptions.map(optCls => {
    optCls.optionItems.map(optItem => {

      options.push({
        ...optItem,
        count: optItem.count || 1,  // 나중에 option 수량 선택을 위함
      })

      return null
    })
    return null
  })

  return {
    ...item,
    count: itemCount,
    options
  }
}


function createCartObjDpos({ item, selectedOptions, itemCount }) {
  const options = []
  selectedOptions.map(optCls => {
    optCls.optionItems.map(optItem => {

      options.push({
        ...optItem,
        count: optItem.count || 1,  // 나중에 option 수량 선택을 위함
      })

      return null
    })
    return null
  })

  return {
    ...item,
    count: itemCount,
    options
  }
}