import React, { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import { debug } from "../settings";
import lzString from "lz-string"

// export const AuthContext = React.createContext();

export function parseTitle(html) {
  let start = html.indexOf("<h1>");
  // console.log(start, html);
  if (start > -1) {
    let end = html.indexOf("</h1>");
    return html.slice(start + 4, end);
  } else return null;
}

export function dec2hex(n) {
  n = parseInt(n);
  var c = "ABCDEF";
  var b = n / 16;
  var r = n % 16;
  b = b - r / 16;
  b = b >= 0 && b <= 9 ? b : c.charAt(b - 10);
  return r >= 0 && r <= 9
    ? b + "" + r
    : b + "" + c.charAt(r - 10);
}

export function logObj(obj) {
  if (debug) console.log(JSON.stringify(obj, null, 2));
}

const clone = (obj) => Object.assign({}, obj);

export function renameKey(obj, key, newKey) {
  const clonedObj = clone(obj);
  const targetKey = clonedObj[key];
  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
}

export const objArr2str = (objArr) => {
  const arr = objArr;
  let list = [];
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i];
    if (typeof item === "object")
      list.push(Object.keys(item)[0]);
  }
  return list.join(", ");
};

export const str2objArr = (str) => {
  let list = [];
  let arr = str.split(",");
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i].trim();
    let obj = {};
    obj[item] = "";
    list.push(obj);
  }
  return list;
};

export const str2arr = (str) => {
  let list = [];
  let arr = str.split(",");
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i].trim();
    list.push(item);
  }
  return list;
};

export function capitalize(str) {
  return str.replace(/^\w/, (c) => c.toUpperCase());
}

export function hex2int(hexString) {
  let hex = hexString.toString();
  let Uint8Array = [];
  for (let n = 0; n < hex.length; n += 2) {
    Uint8Array.push(parseInt(hex.substr(n, 2), 16));
  }
  return Uint8Array;
}

// export function rand(num) {
//   const arr = [];
//   for (let i = 0; i < num; i++) {
//     arr.push(Math.floor(Math.random() * 256));
//   }
//   return arr;
// }

export function rotateRight(arr) {
  let last = arr.pop();
  arr.unshift(last);
  return arr;
}

// export function getStateObjFromInputFields(inputFields, isEdit) {
//   let setObj = { isLoaded: true };
//   for (let i = 0; i < inputFields.length; i++) {
//     let item = inputFields[i];
//     if (isEdit) {
//       // setObj[item.name] = editItem[item.name];
//       setObj[item.name] = "";
//       setObj.isEdit = true;
//     } else {
//       setObj[item.name] = item.defaultValue || "";
//     }
//   }
//   return setObj;
// }

/**
 * @deprecated
 * 
 * @param {Array} param.inputFields
 * @param {Object} param.editItems
 * @returns 
 */
export function createFields({ inputFields, editItems }) {
  let fields = {};
  const isEdit =
    editItems !== null &&
    editItems !== undefined &&
    Object.keys(editItems).length > 0;
  for (let i = 0; i < inputFields.length; i++) {
    let item = inputFields[i];
    // if (editItems) {
    if (isEdit) {
      fields[item.name] = editItems[item.name];
    } else {
      if (item.defaultValue === undefined) {
        if (item.type === "number") fields[item.name] = 0;
        else if (item.inputType === "checkbox") fields[item.name] = false;
        else fields[item.name] = "";
      } else {
        fields[item.name] = item.defaultValue;
      }
    }
  }
  return fields;
}

/**
 * @deprecated
 * 
 * @param {Array} param.inputFields
 * @param {Object} param.editItems
 * @returns 
 */
export function createMenuItem({ inputFields, posItem }) {
  let obj = {};
  for (let i = 0; i < inputFields.length; i++) {
    let item = inputFields[i];
    obj[item.name] = posItem[item.posName ? item.posName : item.name];
  }
  return obj;
}

/**
 * @deprecated
 * 
 * @Description Input form을 자동 생성하기 위한 함수
 *
 * @param {Array} param.inputFields: settings.js 에서 온 db fields
 * @param {Object} param.items: 서버로부터 입수한 data object
 * @returns {Object}
 *
 * items 가 undefined 이면 inputFields 내용으로만 return obj를 구성함.
 */
export function getObjFromInputFields({
  inputFields,
  items,
}) {
  let obj = {};
  for (let i = 0; i < inputFields.length; i++) {
    let item = inputFields[i];
    if (items) {
      obj[item.name] = items[item.name];
    } else {
      obj[item.name] = item.defaultValue || "";
    }
  }
  return obj;
}

// export function log() {
//   if (!debug) return;
//   const args = Array.prototype.slice.call(arguments, 0);
//   let logStr = "";
//   for (let i = 0; i < args.length; i++) {
//     logStr += args[i];
//     if (i < args.length - 1) logStr += " ";
//   }
//   // console.log(args);
//   console.log(logStr);
// }

export function log(msg, data) {
  if (!debug) return;
  const n = 20;
  let spaceLen = msg.length >= n ? msg : n - msg.length;
  const _msg = msg + ' '.repeat(spaceLen);
  // if (Buffer.isBuffer(data)) console.log(_msg, numArray2hexStr(data));
  if (typeof data === 'object') console.log(_msg, JSON.stringify(data));
  else console.log(_msg, data);
}

/**
 *
 * @param {String} querystring location.search
 * @returns {Object}
 */
export const queryString2Obj = (querystring) => {
  const params = new URLSearchParams(querystring);
  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

/**
 * @deprecated
 * @Info check browser information
 * @returns {Object} {isMobileDevice, details}
 */
export const checkBrowser = () => {
  /* Storing user's device details in a variable*/
  let details = navigator.userAgent;

  /* Creating a regular expression 
  containing some mobile devices keywords 
  to search it in details string*/
  let regexp = /android|iphone|kindle|ipad/i;

  /* Using test() method to search regexp in details
  it returns boolean value*/
  let isMobileDevice = regexp.test(details);
  return { isMobileDevice, details };
};

/**
 * @deprecated
 * @param {*} details 
 * @returns 
 */
export const getMobileOsType = (details) => {
  const types = ["Android", "iOS", "Windows NT"];
  let type = null;
  types.forEach((item) => {
    if (details.indexOf(item) > 0) type = item;
  });
  return type;
};

export const isJson = (str) => {
  try {
    var json = JSON.parse(str);
    return typeof json === "object";
  } catch (e) {
    return false;
  }
};

export const json2obj = (json, type = "array") => {
  if (isJson(json)) {
    return JSON.parse(json)
  }
  else {
    if (type === "array") return []
    else return {}
  }
};

/**
 * @used_ib_Update_API
 *
 * @param {*} originalItems
 * @param {*} newItems
 * @returns
 */
export const bodyToUpdate = (originalItems, newItems) => {
  let body = {};
  Object.entries(newItems).forEach(([key, value]) => {
    if (originalItems[key] !== value) body[key] = value;
  });
  return body;
};

//
// 오브젝트를 standard에 기준하여 정렬
//
export const sortObj = (obj, standard) => {
  let sortedObj = obj.sort(function (a, b) {
    return b[standard] - a[standard];
  });

  return sortedObj;
};

function getWindowDimensions(hasWindow) {
  const width = hasWindow ? window.innerWidth : null;
  const height = hasWindow ? window.innerHeight : null;
  return { width, height };
}

export function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined";
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(hasWindow)
  );
  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions(true));
      };
      window.addEventListener("resize", handleResize);
      return () =>
        window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}

/**
 * @deprecated use getPriceStr
 * @param {*} text 
 * @returns 
 */
export function kiloComma(text) {
  return text
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getPriceStr(price) {
  const newPrc = parseInt(price)
  if (isNaN(newPrc)) return "0"
  return kiloComma(newPrc)
}

export function makeOptionInfo(option) {
  const optName = option;
  let optNames = "";
  let optPrices = 0;
  optName.forEach((v, v2, optName) => {
    optNames += ", " + v.name;
    optPrices += v.price;
  });
  optNames = '(' + optNames.substring(2) + ')';
  return { optNames, optPrices };
}

export function getWidth(props) {
  let width = props.dim.width * (props.ratio / 1920);
  if (width < props.minWidth) width = props.minWidth;
  else if (width > props.maxWidth) width = props.maxWidth;

  return width;
}

/**
 * @deprecated
 *
 * @param {*} item inputFields item
 *            check item.required & item.validate
 * @param {*} value state item.value
 * @returns
 */
export const validateInputValue = (item, value) => {
  //
  // required=false 항목은 무조건 valdate 필요없음
  //
  if (item.required === false) {
    // console.log('item not required', item.name)
    return true;
  }

  //
  // validate 지정이 된 것은 type 에 따라 validate
  // validate 지정이 안되이 있으면 required 에 따른 공란만 체크함.
  //
  if (item.validate) {
    const type = item.validate.type;
    if (type === "email") return validateEmail(value);
    if (type === "password") return value.length > 7;
    if (type === "phone_number") return validatePhoneNo(value);
  } else {
    if (value !== "") return true;
  }

  return false;
};

/**
 * @deprecated
 * 
 * @param {*} phoneNo 
 * @returns 
 */
export function validatePhoneNo(phoneNo) {
  const regexp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  if (regexp.test(phoneNo)) return true;
  return false;
}

/**
 * @deprecated
 * 
 * @param {*} password 
 * @returns 
 */
export function validatePassword(password) {
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
  if (!passwordRegex.test(password))
    return {
      isPassword: false,
      message: '8~25자리 영문자, 숫자, 특수문자 조합',
    }
  else
    return {
      isPassword: true,
      message: '올바른 비밀번호 형식 입니다.',
    }
}

/**
 * @deprecated
 * 
 * @param {*} email 
 * @returns 
 */
export function validateEmail(email) {
  const emailRegex =
    /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
  if (!emailRegex.test(email))
    return { isEmail: false, message: '올바른 이메일 형식이 아닙니다.' }
  else
    return { isEmail: true, message: '올바른 이메일 형식 입니다.' }
}

export function isSamePassword(srcText, desText) {
  if (desText === '') return ''
  return srcText === desText ?
    { isSame: true, message: '비밀번호가 일치합니다.' } :
    { isSame: false, message: '비밀번호가 일치하지 않습니다.' }
}

export function findMenu(items, optionName) {
  let itemStr = "";

  for (let i = 0; i < items?.length; i++) {
    for (let j = 0; j < items[i].options?.length; j++) {
      if (items[i]?.options[j] === optionName) {
        itemStr += ", " + items[i].itemName;
      }
    }
  }
  let sub = itemStr.substring(2, itemStr.length);

  return sub;
}

export function gotoPage(props, path, option = null) {
  const { history } = props;
  return history.push(path, option);
}

export async function ImageResizing(image, maxSizeMB = 1, maxWidthOrHeight = 960) {
  const file = image;
  if (debug) console.log('original size: ', file.size / 1000, "k bytes");
  const options = {
    // maxSizeMB: 2,
    // maxWidthOrHeight: 1920,
    maxSizeMB, maxWidthOrHeight,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(file, options);
    if (debug) console.log('compressed size: ', compressedFile.size / 1000, "k bytes");
    return compressedFile;
  } catch (e) {
    if (debug) console.log('file resizing Error: ', e);
    return null;
  }
}

export async function imageCompress({ image, maxSizeMB = 1, maxWidthOrHeight = 1920 }) {
  const file = image;
  const options = {
    maxSizeMB, maxWidthOrHeight,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (e) {
    if (debug) console.log('file resizing Error: ', e);
    return null;
  }
}

export function delay(ms) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), ms)
  });
}

/**
 * @deprecated
 * @param {Array} param.arr
 * @param {String} param.key
 * @param {String} param.type up | down
 * @returns 
 */
export function sortObjArray({ arr, key, type = "up" }) {
  const newArr = [...arr]
  newArr.sort((a, b) => {
    if (a[key] > b[key]) return type === "up" ? 1 : -1
    if (a[key] < b[key]) return type === "down" ? -1 : 1
    return 0
  })
  return newArr
}

export function sortObjArray2({ arr, key, type = "up" }) {
  const newArr = [...arr]
  newArr.sort((a, b) => {
    if (type === "up") {
      if (a[key] >= b[key]) return 1
      else return -1
    } else {
      if (a[key] >= b[key]) return -1
      else return 1
    }
    // return 0
  })
  return newArr
}

export function getNewCode(objArray, key, size) {
  let cnt = 1
  for (let i = 1; i < 10 * size; i++) {
    const arr = objArray.filter(o => parseInt(o[key]) === i)
    if (arr.length === 0) {
      cnt = i
      break;
    }
  }
  return cnt.toString().padStart(size, "0")
}

export function getNewCodeWithPrefix(objArray, key, size, prefix = "C") {
  let cnt = 1
  for (let i = 1; i < 10 * size; i++) {
    const arr = objArray.filter(o => parseInt(o[key].substring(1)) === i)
    if (arr.length === 0) {
      cnt = i
      break;
    }
  }
  return prefix + cnt.toString().padStart(size, "0")
}

export function getObjFromArray(key, value, arr) {
  const codes = arr.filter(o => o[key] === value)
  if (codes.length === 1) return codes[0]
  else return null
}

/**
 * 
 * @param {String} phone_number 
 * @returns String
 */
export function getSecurePhoneNumber(phone_number) {
  if (phone_number === undefined) return ""
  const len = phone_number.length
  let tel = phone_number.substring(0, 3) + "-"
  if (len === 11) tel += 'XXXX' + "-" + phone_number.substring(7)
  else tel += 'XXX' + "-" + phone_number.substring(6)
  return tel
}

/**
 * 
 * @param {String} phone_number 
 * @returns String
 */
export function getMidNumber(phone_number) {
  if (phone_number === undefined) return ""
  const len = phone_number.length
  let d = ""
  if (len === 11) d = phone_number.substring(3, 7)
  else d = phone_number.substring(3, 6)
  return d
}

export function getTimestampFromNow({ now, days = 1, hours = 0, minutes = 0, seconds = 0, milliseconds = 0 }) {
  now.setDate(now.getDate() + days);
  now.setHours(hours)
  now.setMinutes(minutes)
  now.setSeconds(seconds)
  now.setMilliseconds(milliseconds)
  return new Date(now).getTime();
}

export const getTnUrl = (imgUrl) => {
  if (imgUrl === "" || imgUrl === undefined) return ""
  const urlObj = new URL(imgUrl)
  const arr = urlObj.pathname.split('/')
  arr.splice(arr.length - 1, 0, 'tn')
  return urlObj.origin + arr.join('/')
}

export const strCompress = (str) => {
  return lzString.compressToUTF16(str)
}

export const strDecompress = (comp) => {
  return lzString.decompressFromUTF16(comp)
}

export function getShortArray(arr, attrList) {
  const newArr = arr.map(item => {
    const newItem = {}

    Object.keys(item).map(key => {
      if (attrList.includes(key)) newItem[key] = item[key]
      return null
    })

    return newItem
  })

  return newArr
}

export function hasSameName ({ itemObj, arr, key }) {
  let val = false

  arr.map(item => {
    val = val || (item[key] === itemObj[key])
    return null
  })

  return val
}