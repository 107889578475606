import React, { useState } from "react";
import { useStyles, FormInput, ModalForm, FormBox } from "../../../components";
import { useInputFields } from "../../../lib/hooksLib";
import { updateItemOption } from "../../../lib-pos";
import { debug } from "../../../settings";
import { targetLangs } from "../../../settings/strings";

export default function MenuSettingModal(props) {
  const {
    visible, setVisible,
    shopInfo, itemOptions2,
    onload,
  } = props;
  const { gStyle, colors } = useStyles();

  const modalTitle = "메뉴 세팅";
  const menuSettings = itemOptions2.menuSettings || {};

  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useInputFields({
    usePosMenuFirst: menuSettings.usePosMenuFirst ? menuSettings.usePosMenuFirst : false,
    useGuestCnt: menuSettings.useGuestCnt ? menuSettings.useGuestCnt : false,
  });

  const languageList = menuSettings.supportedLangs ? menuSettings.supportedLangs : []
  const [langFields, setLangFields] = useInputFields({
    KR: languageList.includes("KR"), EN: languageList.includes("EN"),
    JP: languageList.includes("JP"), CN: languageList.includes("CN"),
  });

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      // 
      // 예시 supportedLangs = [KR, EN] 
      // 
      const langList = []
      Object.keys(langFields).map(lang => {
        if (langFields[lang]) langList.push(lang);
        return null;
      });

      const menuSettings = { ...fields, supportedLangs: langList }

      if (debug) console.log('menuSettings', menuSettings)

      let res = await updateItemOption({ shopInfo, body: { menuSettings: JSON.stringify(menuSettings) } });
      if (res.err) throw new Error(res.err.message);

      else await onload();
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const styles = {
    titleWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
      marginBottom: "15px"
    },
    formGroup: { margin: "15px 5px" },
    title: { ...gStyle.subtitle, ...gStyle.bold, textAlign: "center" },
    text: { ...gStyle.text, color: colors.dark },
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={modalTitle}
      onSubmit={onSubmit} isLoading={isLoading}
    >
      <div style={styles.formGroup}>
        {shopInfo.posName !== "DUMMY_POS" &&
          <>
            <p style={styles.text}>체크하면 해당 기능이 활성화 됩니다.</p>
            <FormBox>
              <FormInput
                label="POS 메뉴명 사용"
                name="usePosMenuFirst"
                checked={fields.usePosMenuFirst}
                onChange={setFields}
                inputType="checkbox"
              />

              {shopInfo.posName === "UNIONPOS" &&
                <FormInput
                  label="Guest Count 사용"
                  name="useGuestCnt"
                  checked={fields.useGuestCnt}
                  onChange={setFields}
                  inputType="checkbox"
                />
              }

            </FormBox>
          </>
        }
      </div>

      <div style={styles.formGroup}>
        <p style={styles.text}>메뉴언어 지원 리스트 설정(선택된 언어만 지원합니다.)</p>
        <FormBox>
          {
            targetLangs.map((lang, i) => {
              return (
                <FormInput
                  key={i}
                  label={lang.displayName}
                  name={lang.name}
                  checked={langFields[lang.name]}
                  onChange={setLangFields}
                  inputType="checkbox"
                />
              )
            })
          }

        </FormBox>
      </div>
    </ModalForm>
  )
}
