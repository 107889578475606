import React, { useState } from "react";
import { FormInput, Button, ModalForm, useStyles, UnderlineButton } from "../../../components";
import { hasSameName } from "../../../lib/utils";
import { updateItemOption, getGroupList, getGroupUpdateBody } from "../../../lib-pos";
import { useInputFields } from "../../../lib/hooksLib";
import { debug } from "../../../settings";
import { targetLangs } from "../../../settings/strings";

export default function ModifyGroupAliasModal(props) {
  const {
    shopInfo,
    selectedGroup2,
    // setSelectedGroup2,
    visible,
    setIsVisible,
    // itemOptions, 
    itemOptions2,
    onload
  } = props;

  const { gStyle, colors } = useStyles();

  const title = `"${selectedGroup2.name || selectedGroup2.groupName}" 그룹 편집`;

  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useInputFields({
    displayName: (shopInfo.posName === "DUMMY_POS") ? selectedGroup2.groupName : selectedGroup2.displayName,
    hidden: selectedGroup2.hidden === undefined ? false : selectedGroup2.hidden
  });

  const nameLang = selectedGroup2.nameLang || {};
  const supportedLangs = itemOptions2.menuSettings?.supportedLangs || [];

  const defaultLangFields = {}
  targetLangs.map((lang) => {
    if (supportedLangs.includes(lang.name)) {
      defaultLangFields[lang.name] = nameLang[lang.name] || ""
    }
    return null;
  })

  const [langFields, setLangFields] = useInputFields(defaultLangFields)

  // 
  // 변경사항이 없거나, "" 이면 false
  // 
  const validate = () => {
    let val = true;
    // val = val && (fields.displayName !== selectedGroup2.displayName);
    val = val && (fields.displayName !== "");
    return val;
  }

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const groupList = getGroupList({ itemOptions2 });

      let newGroupList = groupList.map(group => {
        if (isSame({ shopInfo, group, selectedGroup2 })) {
          const newGroup = {
            ...group,
            nameLang: langFields,
            displayName: fields.displayName,
            hidden: fields.hidden
          }

          if (shopInfo.posName === "DUMMY_POS") {
            newGroup.groupName = newGroup.displayName

            const restArr = groupList.filter(g => g.groupCode !== newGroup.groupCode)
            if (hasSameName({ itemObj: newGroup, arr: restArr, key: "groupName" })) {
              throw Error("동일한 이름이 이미 존재합니다.")
            }
          }

          return newGroup
        }
        return group;
      });

      if (debug) console.table(newGroupList)

      const bodyObj = getGroupUpdateBody({ itemOptions2, groupList: newGroupList })

      if (debug) console.log('bodyObj', bodyObj)

      // throw Error('test...')

      let res = await updateItemOption({ shopInfo, body: bodyObj });
      if (res.err) throw new Error(res.err.message);
      await onload();
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setIsVisible(false);
    }
  }

  const onDeleteGroup = async () => {
    try {
      if (props.items.length > 0) throw Error("메뉴 아이템을 먼저 삭제해야 합니다.")

      console.log('='.repeat(50), "아이템 수", props.items.length)
      console.log('selectedGroup2', selectedGroup2)
      console.table(itemOptions2.groupList)

      const newGroupList = [...itemOptions2.groupList]
      const idx = newGroupList.findIndex(grp => grp.groupCode === selectedGroup2.groupCode)
      newGroupList.splice(idx, 1)
      console.table(newGroupList)

      const body = {
        groupList: JSON.stringify(newGroupList)
      }
      let res = await updateItemOption({ shopInfo, body });
      if (res.err) throw new Error(res.err.message);

    } catch (e) {
      alert(e.message)
    } finally {
      window.location.reload()
    }
  }

  const styles = {
    text: { ...gStyle.text, marginBottom: "3px" },
    formGroup: { margin: 0, padding: 5 }
  };

  return (
    <ModalForm visible={visible} setIsVisible={setIsVisible} title={title}>
      <div style={styles.formGroup}>
        <FormInput
          label="그룹 별칭(기본값) 편집"
          name="displayName"
          validated={validate()}
          value={fields.displayName}
          onChange={setFields}
        />
      </div>

      <div style={styles.formGroup}>
        <div style={{ border: "1px solid #555", padding: 10 }}>
          <FormInput
            style={{ marginBottom: 0 }}
            label="그룹 숨기기"
            name="hidden"
            checked={fields.hidden}
            onChange={setFields}
            inputType="checkbox"
          />
        </div>
      </div>

      {
        (supportedLangs.length) > 0 &&
        <div style={styles.formGroup}>
          <p style={styles.text}>언어별 그룹명 편집</p>
          <div style={{ border: "1px solid #555", padding: 10 }}>
            {
              targetLangs.map((lang, i) => {
                if (supportedLangs.includes(lang.name)) {
                  return (
                    <FormInput
                      key={lang.name}
                      label={lang.displayName}
                      name={lang.name}
                      value={langFields[lang.name]}
                      onChange={setLangFields}
                    />
                  )
                } else return null
              })
            }
          </div>
        </div>
      }

      <br />

      <Button
        disabled={!validate()}
        size="sm"
        variant="primary"
        width="100%"
        title='수정'
        onClick={onSubmit}
        isLoading={isLoading}
      />

      {
        shopInfo.posName === "DUMMY_POS" &&
        <UnderlineButton title="삭제" onClick={onDeleteGroup} />
      }
    </ModalForm>
  )
}

function isSame({ shopInfo, group, selectedGroup2 }) {
  let isSameGroup = false;
  if (shopInfo.posName === "OKPOS") {
    isSameGroup = group.TU_CLS_CD === selectedGroup2.TU_CLS_CD
  } else if (
    shopInfo.posName === "EASY_POS" ||
    shopInfo.posName === "DUMMY_POS" ||
    shopInfo.posName === "OASIS_POS"
  ) {
    isSameGroup = group.groupCode === selectedGroup2.groupCode
  } else {
    isSameGroup = group.GrpCode === selectedGroup2.GrpCode
  }
  return isSameGroup
}