import React, { useEffect, useState } from "react";
import {
  useStyles, ModalForm,
  // CustomTable, 
  FormInput, Button
} from "../../../components";
import { CustomTable2 } from "../../../components/Tables2";
import { debug } from "../../../settings";
import {
  onlItemOptionCreate,
  onlItemOptionUpdate,
  shopItemOptionUpdate
} from "../../../lib/ec2-api-lib";
import { useInputFields } from "../../../lib/hooksLib";
import { createFields, createInputProps } from "../../../components/PostFormSection";
import { getNewCodeWithPrefix, sortObjArray2, hasSameName } from "../../../lib/utils";
import { createNewOptionList } from "../../../lib-pos";

const str = {
  noItemsSelected: "선택된 항목이 없습니다.",
  inValidMaxQty: "제한 수량이 1이 아니면 비워 두세요!",
  notSuportedPos: "POS NOT SUPPORTED"
}

const itemHeader = [
  { name: "checked", displayName: "선택", type: "checkbox" },
  { name: "itemName", displayName: "아이템명" },
  { name: "itemCode", displayName: "아이템 코드" },
  { name: "price", displayName: "가격", type: "number" },
]

const optItemInputFieldsOKpos = [
  { name: "itemCode", displayName: "아이템 코드", readOnly: true },
  { name: "name", displayName: "아이템 명", readOnly: true },
  { name: "optionPrice", displayName: "옵션 가격", readOnly: true },
  { name: "maxQty", displayName: "제한 수량", defaultValue: "1" }, // 1이 아니면 수량 변경 가능
]

const optItemInputFieldsDpos = [
  { name: "optionItemCode", displayName: "아이템 코드", readOnly: true },
  { name: "optionItemName", displayName: "아이템 명" },
  { name: "price", displayName: "옵션 가격", defaultValue: "0" },
  { name: "maxQty", displayName: "제한 수량", defaultValue: "1" }, // (1 혹은 undefined) 이면 수량 한개만 가능
]

export default function OnlOptionItemModal(props) {
  const {
    visible, setVisible,
    optClsList, optItemList,
    shopInfo, posName,
    itemList3,
    onload,
    isNewOption,
    item, isEdit
  } = props;
  const { gStyle } = useStyles();
  const modalTitle = "옵션 아이템 " + (isEdit ? "편집" : "추가");

  const [isLoading, setIsLoading] = useState(false);
  const [itemList2, setItemList2] = useState([])

  // let groupCodeKey = "groupCode"
  // let groupNameKey = "name"
  // let classCodeKey = "classCode"
  // let classListKey = "classList"
  // let classNameKey = "name"
  let itemCodeKey = "itemCode"
  let itemListKey = "itemList"

  if (posName === "DUMMY_POS") {
    // groupCodeKey = "optionGroupCode"
    // groupNameKey = "optionGroupName"
    // classCodeKey = "optionClassCode"
    // classNameKey = "optionClassName"
    // classListKey = "optionClassCodes"
    itemCodeKey = "optionItemCode"
    itemListKey = "optionItemCodes"
  }

  const inputFields = (posName === "OKPOS") ? optItemInputFieldsOKpos : optItemInputFieldsDpos

  const defaultFields = createFields({ inputFields, editItems: item })
  const [fields, setFields] = useInputFields(defaultFields)

  useEffect(() => {
    if (posName === "OKPOS") startOkpos()
    if (posName === "DUMMY_POS") startDpos()
  }, [props])

  const startDpos = () => {
    if (!isEdit) {
      const newCode = getNewCodeWithPrefix(optItemList, "optionItemCode", 3, "C")
      setFields({ target: { name: "optionItemCode", value: newCode } })
    }
  }

  const startOkpos = () => {
    if (isEdit) {
      if (debug) console.table([item])
    } else {
      const newItems = itemList3.filter(item => item.SIDE_MENU_YN === "N")
      const newItems1 = sortObjArray2({ arr: newItems, key: "itemName" })
      const newItems2 = []
      newItems1.map(item => {
        const existItems = optItemList.filter(optItem => optItem.itemCode === item.itemCode)
        if (existItems.length === 0) newItems2.push({ ...item, checked: false })
      })

      // const newItems3 = getShortArray(newItems2, [
      //   "itemCode",
      //   "itemName",
      //   "price",
      // ])
      // console.table(newItems3)

      setItemList2(newItems2)
    }
  }

  const onSubmitUpdate = async () => {
    try {
      setIsLoading(true);
      let res = {}
      if (posName === "DUMMY_POS") res = await _updateOptionItemDpos()
      else res = await _updateOptionItemOKpos()
      if (res.err) throw Error(res.err.message)
      onload()
    }
    catch (e) {
      // console.log(e)
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const _updateOptionItemOKpos = async () => {
    try {

      if (!(fields.maxQty === "" || fields.maxQty === "1")) {
        throw Error(str.inValidMaxQty)
      }
      const newItem = {
        ...item,
        ...fields,
      }

      // console.table([item, newItem])

      const newOptItems = optItemList.map(o => {
        if (o.itemCode === newItem.itemCode) return newItem
        else return o
      })

      const params = {
        shopId: shopInfo.shopId, posName, body: {
          optionItemList: JSON.stringify(newOptItems),
        }
      }

      let res = await onlItemOptionUpdate(params)
      if (res.err) throw Error(res.err.message)
      return {}
    } catch (e) {
      if (debug) console.log(e)
      return { err: { message: e.message } }
    }
  }

  const _updateOptionItemDpos = async () => {
    try {
      if (Number.isNaN(parseInt(fields.price))) throw Error("INVALID VALUE - price")
      if (Number.isNaN(parseInt(fields.maxQty))) throw Error("INVALID VALUE - maxQty")

      const newItem = {
        ...item,
        ...fields,
        price: parseInt(fields.price),
        maxQty: parseInt(fields.maxQty),
      }

      const restArr = optItemList.filter(o => o.optionItemCode !== newItem.optionItemCode)
      if (hasSameName({ itemObj: newItem, arr: restArr, key: "optionItemName" })) {
        throw Error("동일한 이름이 이미 존재합니다.")
      }

      const newOptItems = optItemList.map(o => {
        if (o.optionItemCode === newItem.optionItemCode) return newItem
        else return o
      })

      const params = {
        shopId: shopInfo.shopId,
        optionName: posName,
        body: {
          optionItemList: JSON.stringify(newOptItems),
        }
      }

      let res = await shopItemOptionUpdate(params)
      if (res.err) throw Error(res.err.message)

      return {}
    } catch (e) {
      if (debug) console.log(e)
      return { err: { message: e.message } }
    }
  }

  const onSubmitDel = async () => {
    try {
      setIsLoading(true);

      const newOptItems = [...optItemList]
      const idx = newOptItems.findIndex(obj => obj[itemCodeKey] === item[itemCodeKey])
      newOptItems.splice(idx, 1)

      const newOptClsList = [...optClsList]
      const newClsList = newOptClsList.map(cls => {
        if (cls[itemListKey].includes(item[itemCodeKey])) {
          const newItemList = cls[itemListKey].filter(code => code !== item[itemCodeKey])
          return {
            ...cls,
            [itemListKey]: newItemList
          }
        }
        return cls;
      })

      const newOptItems2 = createNewOptionList({ shopInfo, optionList: newOptItems })
      const newClsList2 = createNewOptionList({ shopInfo, optionList: newClsList })

      if (debug) {
        console.log("=".repeat(50), "Deleted infoHtml, selected attributes")
        console.table(newClsList2)
        console.table(newOptItems2)
      }

      const params = {
        shopId: shopInfo.shopId, posName, body: {
          optionItemList: JSON.stringify(newOptItems2),
          optionClassList: JSON.stringify(newClsList2),
        }
      }

      let res = {}
      if (posName === "OKPOS") {
        if (isNewOption) res = await onlItemOptionCreate(params)
        else res = await onlItemOptionUpdate(params)
      } else {
        delete params.posName;
        params.optionName = posName
        res = await shopItemOptionUpdate(params)
      }

      if (res.err) throw Error(res.err.message)
      onload()
    } catch (e) {
      if (debug) console.log(e)
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const validated = () => {
    let val = true;
    const checkedItems = itemList2.filter(item => item.checked)
    return val && checkedItems.length > 0
  }

  const onSubmitAdd = async () => {
    try {
      setIsLoading(true);

      // 
      // OKPOS는 기존의 itemList에서 check 항목을 가져온다.
      // 
      if (posName === "OKPOS") {
        if (!validated()) throw Error(str.noItemsSelected)

        const selectedItems = []
        itemList2.map(item => {
          if (item.checked) {
            selectedItems.push({
              itemCode: item.itemCode,
              name: item.itemName,
              price: parseInt(item.price),
              optionPrice: parseInt(item.price)
            })
          }
          return null
        })

        const newOptItems = [...optItemList, ...selectedItems]

        // console.log('='.repeat(50), 'newOptItems')
        // console.table(selectedItems)
        // // console.table(newOptItems)

        // throw Error('test...')

        const params = {
          shopId: shopInfo.shopId, posName, body: { optionItemList: JSON.stringify(newOptItems) }
        }
        let res = {}
        if (isNewOption) res = await onlItemOptionCreate(params)
        else res = await onlItemOptionUpdate(params)

        if (res.err) throw Error(res.err.message)

      } else if (posName === "DUMMY_POS") {

        if (fields.optionItemName === "") throw Error("INVALID VALUE - optionItemName")
        if (Number.isNaN(parseInt(fields.price))) throw Error("INVALID VALUE - price")
        if (Number.isNaN(parseInt(fields.maxQty))) throw Error("INVALID VALUE - maxQty")
        if (hasSameName({ itemObj: fields, arr: optItemList, key: "optionItemName" })) {
          throw Error("동일한 이름이 이미 존재합니다.")
        }

        const newItems = [
          ...optItemList,
          {
            ...fields,
            price: parseInt(fields.price),
            maxQty: parseInt(fields.maxQty),
          }
        ]

        const params = {
          shopId: shopInfo.shopId,
          optionName: posName,
          body: {
            optionItemList: JSON.stringify(newItems),
          }
        }

        let res = await shopItemOptionUpdate(params)
        if (res.err) throw Error(res.err.message)
      } else {
        throw Error(str.notSuportedPos)
      }
      onload()
    } catch (e) {
      if (debug) console.log(e)
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const onChangeChecked = (checkedItem) => {
    if (debug) console.log(checkedItem.itemCode, checkedItem.itemName, checkedItem.checked)
    const newItems = itemList2.map(item => {
      if (item.itemCode === checkedItem.itemCode) {
        return {
          ...item,
          checked: !checkedItem.checked
        }
      } else return item
    })

    setItemList2(newItems)
  }

  const styles = {
    formGroup: { margin: "15px 5px", maxHeight: "70vh", overflow: "auto" },
    btnWrap: {
      margin: "15px 5px",
      display: "flex",
      justifyContent: "space-between"
    }
  }

  return (
    <ModalForm
      visible={visible}
      setIsVisible={setVisible}
      title={modalTitle}
      isLoading={isLoading}
      size={isEdit ? "" : (posName === "DUMMY_POS") ? "" : "lg"}
      backdrop="static"
    >
      {
        (isEdit || (posName === "DUMMY_POS")) &&
        <div style={styles.formGroup}>
          <p style={{ ...gStyle.text, padding: "0px 5px" }}>
            사용하지 않는 아이템은 삭제해 주세요. 삭제해도 메뉴 아이템에 영향을 주지 않습니다.
          </p>
          <div style={styles.formGroup}>
            {
              inputFields.map((o, index) => {
                const myProps = createInputProps({ item: o, index, fields, setFields });
                return <FormInput {...props} {...myProps} />;
              })
            }

          </div>
          <div style={styles.btnWrap}>
            <Button
              variant="secondary"
              onClick={onSubmitDel}
              size="sm"
              style={{ width: "30%" }}
              isLoading={isLoading}
            >삭제</Button>
            <Button
              variant="primary"
              onClick={isEdit ? onSubmitUpdate : onSubmitAdd}
              size="sm"
              style={{ width: "30%" }}
              isLoading={isLoading}
            >확인</Button>
          </div>
        </div>
      }
      {(!isEdit && (posName === "OKPOS")) &&
        <div style={styles.formGroup}>
          <p style={gStyle.text}>체크 후 확인을 누르면 등록 됩니다.</p>
          <CustomTable2
            tableHeader={itemHeader}
            rowItems={itemList2}
            setRowItems={setItemList2}
            onChangeChecked={onChangeChecked}
          />
          <Button
            variant="primary"
            onClick={onSubmitAdd}
            size="sm"
            style={{ width: "100%" }}
            isLoading={isLoading}>확인</Button>
        </div>
      }
    </ModalForm>
  )
}
