import React, { useEffect, useState } from "react";
import { useInputFields } from "../../../lib/hooksLib";
import {
  onlItemOptionCreate,
  onlItemOptionUpdate,
  shopItemOptionUpdate
} from "../../../lib/ec2-api-lib";
import { getNewCode, getNewCodeWithPrefix, hasSameName } from "../../../lib/utils";
import OnlOptionModalUi from "./OnlOptionModalUi";
import { debug } from "../../../settings";

const str = {
  noItemsSelected: "선택된 항목이 없습니다!",
  noName: "명칭이 비어 있습니다!"
}

export default function OnlOptionClassModal(props) {
  const {
    // visible, // modalUi에서 사용
    setVisible,
    optGroups,
    optClsList,
    optItemList,
    shopInfo, posName,
    onload,
    isNewOption,
    isEdit,
    item,         // class or group Object
    tableHeaders
  } = props;

  const modalTitle = isEdit ? "옵션 편집" : "옵션 추가"
  const [isLoading, setIsLoading] = useState(false);
  const [tHeader, setTHeader] = useState([])
  const [tHeaderIncluded, setTHeaderIncluded] = useState([])

  let groupCodeKey = "groupCode"
  let groupNameKey = "name"
  let classCodeKey = "classCode"
  let classListKey = "classList"
  let classNameKey = "name"
  let itemCodeKey = "itemCode"
  let itemListKey = "itemList"

  if (posName === "DUMMY_POS") {
    groupCodeKey = "optionGroupCode"
    groupNameKey = "optionGroupName"
    classCodeKey = "optionClassCode"
    classNameKey = "optionClassName"
    classListKey = "optionClassCodes"
    itemCodeKey = "optionItemCode"
    itemListKey = "optionItemCodes"
  }

  // OKPOS
  let classCode = isEdit ? item[classCodeKey] : getNewCode(optClsList, "classCode", 4)
  let name = isEdit ? item.name : ""

  if (posName === "DUMMY_POS") {
    classCode = isEdit ? item.optionClassCode : getNewCodeWithPrefix(optClsList, "optionClassCode", 3)
    name = isEdit ? item.optionClassName : ""
  }

  const defaultFields = {
    classCode, 
    name,
    required: isEdit ? item.required : false, 
  }

  if (posName === "DUMMY_POS") {
    defaultFields.maxQty = "1"
  }

  const [fields, setFields] = useInputFields(defaultFields)

  // 
  // optItemList + checkbox
  // 
  const [optItems, setOptItems] = useState([])
  const [showFullItems, setShowFullItems] = useState(true)

  const [includedItems, setIncludedItems] = useState([])

  useEffect(() => {
    function start() {
      if (isEdit) {
        const includedList = []
        item[itemListKey].map(itemCode => {
          const items = optItemList.filter(o => o[itemCodeKey] === itemCode)
          if (items.length === 1) includedList.push(items[0])
        })
        setIncludedItems(includedList)

        // 
        // Sorting을 위한 header
        // 
        const tableHeaderIncluded = [...tableHeaders.items]
        tableHeaderIncluded.unshift(tableHeaders.sorting)
        setTHeaderIncluded(tableHeaderIncluded)
        setShowFullItems(false)
      }

      // 
      // Full ItemList 테이블용 데이터 + checkbox
      // 
      const tableHeader = [...tableHeaders.items]
      tableHeader.unshift(tableHeaders.checkObj)
      setTHeader(tableHeader)

      const newItems = optItemList.map(o => {
        if (isEdit) {
          return { ...o, checked: item[itemListKey].includes(o[itemCodeKey]) }
        } else return { ...o, checked: false }
      })

      setOptItems(newItems)

    }
    start()
  }, [item, tableHeaders, optItemList])

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const selectedItemCodeList = includedItems.map(o => o[itemCodeKey])
      const newClass = {
        [classCodeKey]: fields.classCode,
        [classNameKey]: fields.name,
        required: fields.required,
        [itemListKey]: selectedItemCodeList
      }

      if(posName === "DUMMY_POS") {
        newClass.maxQty = fields.maxQty
      }

      // 
      // Validation
      // 
      if (newClass[classNameKey] === "") throw Error(str.noName)

      let newItems = []
      if (isEdit) {

        const restArr = optClsList.filter(g => g[classCodeKey] !== newClass[classCodeKey])
        if (hasSameName({ itemObj: newClass, arr: restArr, key: classNameKey })) {
          throw Error("동일한 이름이 이미 존재합니다.")
        }

        newItems = optClsList.map(optCls => {
          if (optCls[classCodeKey] === newClass[classCodeKey]) return newClass
          else {
            delete optCls.infoHtml
            delete optCls.selected
            return optCls
          }
        })
      }

      else {
        newItems = [...optClsList]

        if (hasSameName({ itemObj: newClass, arr: newItems, key: classCodeKey })) {
          throw Error("동일한 코드가 이미 존재합니다.")
        }

        if (hasSameName({ itemObj: newClass, arr: newItems, key: classNameKey })) {
          throw Error("동일한 이름이 이미 존재합니다.")
        }

        newItems.push(newClass)
      }

      const params = {
        shopId: shopInfo.shopId,
        body: { optionClassList: JSON.stringify(newItems) }
      }

      let res = {}
      if (posName === "OKPOS") {
        params.posName = posName
        if (isNewOption) res = await onlItemOptionCreate(params)
        else res = await onlItemOptionUpdate(params)
      }

      // DUMMY_POS
      else {
        params.optionName = posName
        res = await shopItemOptionUpdate(params)
      }

      if (res.err) throw Error(res.err.message)

      onload()
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const onSubmitDel = async () => {
    try {
      setIsLoading(true);

      const newClsList = [...optClsList]
      const idx = newClsList.findIndex(obj => obj[classCodeKey] === item[classCodeKey])
      newClsList.splice(idx, 1)

      const newGroupList = optGroups.map(o => {
        if (o[classListKey].includes(item[classCodeKey])) {
          const newList = o[classListKey].filter(code => code !== item[classCodeKey])
          return {
            ...o,
            [classListKey]: newList
          }
        }
        return o;
      })

      // console.table(newClsList)
      // console.table(newGroupList)

      const params = {
        shopId: shopInfo.shopId,
        posName: shopInfo.posName,
        body: {
          optionClassList: JSON.stringify(newClsList),
          optionGroupList: JSON.stringify(newGroupList),
        }
      }

      let res = {}
      if (posName === "OKPOS") {
        if (isNewOption) res = await onlItemOptionCreate(params)
        else res = await onlItemOptionUpdate(params)
      } else {
        delete params.posName
        params.optionName = posName // dtTable key 변경
        res = await shopItemOptionUpdate(params)
      }
      if (res.err) throw Error(res.err.message)

      onload()
    } catch (e) {
      if (debug) console.log(e)
      alert(e.message);
    } finally {
      setIsLoading(false);
      setVisible(false);
    }
  }

  const onChangeChecked = (checkedItem) => {
    const newItems = optItems.map(item => {
      if (item[itemCodeKey] === checkedItem[itemCodeKey]) {
        return { ...item, checked: !checkedItem.checked }
      } else return item
    })
    setOptItems(newItems)

    //
    // 해당 아이템 리스트 변경
    // 
    const newIncludedItems = [...includedItems]
    if (!checkedItem.checked) {
      newIncludedItems.push(checkedItem)
    } else {
      const idx = newIncludedItems.findIndex(o => o[itemCodeKey] === checkedItem[itemCodeKey])
      newIncludedItems.splice(idx, 1)
    }

    setIncludedItems(newIncludedItems)
  }

  const sorting = (obj, direction) => {
    const newItems = [...includedItems]
    let idx = newItems.findIndex(o => o[itemCodeKey] === obj[itemCodeKey])

    newItems.splice(idx, 1)
    if (direction === "up") {
      idx = idx === 0 ? idx : --idx
    } else {
      idx = idx === newItems.length ? idx : ++idx
    }
    newItems.splice(idx, 0, obj)

    setIncludedItems(newItems)
  }

  const otherProps = {
    isLoading,
    tHeader, tHeaderIncluded,
    fields, setFields,
    optItems,
    includedItems,
    showFullItems, setShowFullItems,
    onSubmit, onSubmitDel, onChangeChecked, sorting
  }

  return (
    <OnlOptionModalUi
      {...props}
      {...otherProps}
      type="class"
      modalTitle={modalTitle}
    />
  )
}
