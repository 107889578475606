import { sortObjArray2, getShortArray } from "../../../../lib/utils";
import { notSupported } from "../../../../lib-pos/posList";

const pos = {
  "UNIONPOS": { createItemObj: createItemObjUnionpos },
  "OKPOS": { createItemObj: createItemObjOkpos },
  "OASIS_POS": { createItemObj: createItemObjOasispos },
  "EASY_POS": { createItemObj: createItemObjEasypos2 },
  "DUMMY_POS": { createItemObj: createItemObjDpos },
}

const posList = Object.keys(pos)

/**
 * @create_item_detail_for_detail_section 
 */
export function createItemObj2({ shopInfo, ...rest }) {
  if (!posList.includes(shopInfo.posName)) return notSupported()
  return pos[shopInfo.posName].createItemObj({ ...rest })
}

function createItemObjUnionpos({ item, itemList, itemOptions2 }) {
  const SetMenuList = itemOptions2.SetMenuList;
  const ChoiceMenuList = itemOptions2.ChoiceMenuList;
  const itemObj = {}

  // 
  // itemObj = {item}
  // 
  if (item.menuType2 !== "CHOICE") {
    itemObj.item = item;
  }

  /** @단품메뉴 */
  if (item.menuType2 === undefined || item.menuType2 === "") {
    return itemObj;
  }

  console.log('\n')
  const itemInfo = `Item menuCode=${item.menuCode} itemCode=${item.itemCode} itemName=${item.itemName}`
  console.log("=".repeat(50), itemInfo)

  // 
  // itemObj = {item, SetMenuList, SetMenuItemList}
  // 
  if (item.menuType2 === "FIXED" || item.menuType2 === "DYNAMIC") {

    let _setMenuList = SetMenuList.filter(el => el.MenuCode === item.menuCode)
    itemObj.SetMenuList = _setMenuList

    const setMenuItemList = []
    _setMenuList.map(menu => {
      const newList = itemList.filter(el => el.itemCode === menu.ItemCode)
      if (newList.length === 1) {
        setMenuItemList.push({ ...newList[0], ...menu })
      }
      return null
    })

    itemObj.SetMenuItemList = setMenuItemList
  }

  /** @고정세트메뉴 */
  if (item.menuType2 === "FIXED") return itemObj

  /** @변동세트메뉴 */

  const optionClassList = []
  itemObj.SetMenuItemList.map(cls => {

    const setMenuInfo = `SetMenu menuCode=${cls.menuCode} itemCode=${cls.itemCode} menuName=${cls.itemName}`
    console.log("-".repeat(50), setMenuInfo)

    // 
    // setMenu 가 optionClass, choiceMenu가 optionItem 이다. 
    //    -> 즉 setMenuList 에서 optionClassList를 choiceMenuList optionItemList 를 생성한다.
    //    -> itemList.item은 optionItem의 정보가 들어있다.
    //  optionClassList, optionItemList 의 파라미터는 oasis 함수위에 설명
    // 
    const choiceList = ChoiceMenuList.filter(el => el.MenuCode === cls.menuCode)

    const optionItemList = choiceList.map(menu => {
      const items = itemList.filter(el => el.itemCode === menu.ItemCode)

      console.log('ChoiceMenu', menu.MenuCode, menu.ItemCode, items[0].itemName)

      return {
        ...menu,
        optionClassCode: menu.MenuCode,
        optionItemCode: menu.ItemCode,
        optionItemName: items[0].itemName,
        itemInfo: items[0],
        optPrice: menu.Price,
        itemList: items,                      // deprecated
        itemName: items[0].itemName,          // deprecated
        itemName2: items[0].itemName2,        // deprecated
      }
    })

    let required = false
    let maxQty = 1
    let subCol1 = cls.subCol1
    if (subCol1 !== undefined) {
      if (cls.subCol1.includes('ESSENTIAL')) required = true
      if (cls.subCol1.includes('QTYMGR')) maxQty = 99
    } else {
      subCol1 = ""
    }

    optionClassList.push({
      // ...item,
      subCol1,
      itemCode: cls.itemCode,
      itemName: cls.itemName,
      optionClassCode: cls.menuCode,    // class 필수 항목
      optionClassName: cls.itemName,    // class 필수 항목
      required,                         // class 필수 항목
      maxQty,                           // class 필수 항목
      // ChoiceMenuList: optionItemList, // deprecated
      optionItemList                    // class 필수 항목
    })
    return null
  })

  // itemObj.SetMenuItemList = choiceMenuList
  const optClsList = sortObjArray2({ arr: optionClassList, key: "required", type: "up" })
  itemObj.SetMenuItemList = optClsList
  itemObj.optionClassList = optClsList

  return itemObj;
}

function createItemObjOkpos({ item, itemList, itemOptions2 }) {
  const itemObj = { item }
  if (item["SIDE_MENU_YN"] === "N") return itemObj

  const optionClassList = []

  // 
  // SDS 처리
  // 
  if (item["SDS_GRP_CD"]) {
    const sdsClsList = itemOptions2.SdsClsList.filter(cls => {
      return cls["SDS_GRP_CD"] === item["SDS_GRP_CD"]
    })

    sdsClsList.map(cls => {
      const sdsCdList = itemOptions2.SdsCdList.filter(sds => {
        return sds["SDS_CLS_CD"] === cls["SDS_CLS_CD"]
      })

      const maxQty = cls["SDS_MAC_QTY"] === "1" ? 1 : 99
      const optionClass = {
        optionClassCode: cls["SDS_CLS_CD"],
        optionClassName: cls["SDS_CLS_NM"],
        required: cls.required || false,
        maxQty,
      }

      const optionItemList = sdsCdList.map(sds => {
        // const items = itemList.filter(item => item.itemCode === sds["PROD_CD"])
        return {
          ...sds,
          optionClassCode: cls["SDS_CLS_CD"],
          // optionItemCode: items[0].itemCode,
          // optionItemName: items[0].itemName,
          optionItemCode: sds["SDS_CD"],
          optionItemName: sds["SDS_NM"],
          // itemCode: items[0].itemCode,
          // itemInfo: items[0],
          optPrice: parseInt(sds["SDS_PROD_UPRC"]),
        }
      })

      optionClassList.push({
        ...optionClass,
        optionItemList
      })
      return null
    })
  }

  // 
  // SDA 처리 : item정보가 없다.
  // 
  if (item["SDA_CLS_CD"]) {
    const sdaClsList = itemOptions2.SdaClsList.filter(cls => {
      return cls["SDA_CLS_CD"] === item["SDA_CLS_CD"]
    })

    sdaClsList.map(cls => {
      const sdaCdList = itemOptions2.SdaCdList.filter(sda => {
        return sda["SDA_CLS_CD"] === cls["SDA_CLS_CD"]
      })

      const optionClass = {
        optionClassCode: cls["SDA_CLS_CD"],
        optionClassName: cls["SDA_CLS_NM"],
        required: true,
        maxQty: "1",
      }

      const optionItemList = sdaCdList.map(sda => {
        return {
          ...sda,
          optionClassCode: cls["SDA_CLS_CD"],
          optionItemCode: sda["SDA_CD"],
          optionItemName: sda["SDA_NM"],
          optPrice: 0,
        }
      })

      optionClassList.push({
        ...optionClass,
        optionItemList
      })

      return null
    })
  }

  itemObj.SetMenuItemList = optionClassList
  itemObj.optionClassList = optionClassList

  // console.table(itemObj.optionClassList)
  // itemObj.optionClassList.map(cls => {
  //   if (cls.optionItemList) console.table(cls.optionItemList)
  // })

  return itemObj;
}

/** @optionClassList [{ optionClassCode, optionClassName, required, maxQty, optionItemList, ...rest}] */
/** @optionItemList [{ optionItemCode, optionItemName, optPrice, ...rest}] */

function createItemObjOasispos({ item, itemList, itemOptions2 }) {
  const SetMenuList = itemOptions2.setMenuList;
  const itemObj = { item }

  /** @단품메뉴 */
  if (item.isset !== "1") {
    return itemObj;
  }

  // 
  // itemObj = {item, SetMenuList, optionItemList}
  // 
  let _setMenuList = SetMenuList.filter(el => el.setcode === item.itemCode)
  itemObj.SetMenuList = _setMenuList


  /** @optionItemList { optionClassCode, optionClassName, required, maxQty, optionItemList} */
  const setMenuItemList = []
  _setMenuList.map(menu => {
    const newList = itemList.filter(item => item.itemCode === menu.subcode)
    if (newList.length === 1) {
      setMenuItemList.push({
        ...newList[0],
        ...menu,
        optionItemCode: menu.subcode,
        optionItemName: newList[0].itemName,
        optPrice: menu.unitprice,
      })
    }
    return null
  })

  _logOasisOptionItems(setMenuItemList)


  itemObj.SetMenuItemList = setMenuItemList

  // 
  // optionClass 에 해당하는 항목이 없으므로 class 정보를 임의로 생성해준다
  //
  const optionClassList = []
  setMenuItemList.map((item, i) => {
    optionClassList.push({
      optionClassCode: (i + 1000).toString(),
      optionClassName: "추가 옵션 " + item.itemName,
      required: false,
      // required: true,
      maxQty: item.maxqty.toString(),
      optionItemList: [item]
    })
    return null
  })

  const optClsList = sortObjArray2({ arr: optionClassList, key: "required", type: "up" })
  itemObj.SetMenuItemList = optClsList
  itemObj.optionClassList = optClsList

  return itemObj;
}

function _logOasisOptionItems(setMenuItems) {
  const fields = ["itemCode", "itemName", "price", "settype", "unitprice"]

  const newItems = setMenuItems.map(item => {
    const newItem = {}
    fields.map(f => {
      newItem[f] = item[f]
      return null
    })
    return newItem
  })
  console.table(newItems)
}

/** @optionClassList [{ optionClassCode, optionClassName, required, maxQty, optionItemList, ...rest}] */
/** @optionItemList [{ optionClassCode, optionItemCode, optionItemName, optPrice, ...rest}] */

function createItemObjEasypos2({ item, itemList, itemOptions2 }) {

  const itemObj = { item }

  /** @단품메뉴 */
  if (item.KIOSK_SUB_MENU_TYPE !== "Y") {
    return itemObj;
  }

  // console.log("=".repeat(50), "EASY_POS Option Menu")

  const itemOrderClassList = itemOptions2.itemOrderClassList
  const orderClassList = itemOptions2.orderClassList
  const orderList = itemOptions2.orderList

  // console.log('\n', "-".repeat(50), "all orderClassList")
  // logOrderClassListEasypos(orderClassList)

  // 
  // optionClassList 찾기
  // 
  const itemOrderClasses = itemOrderClassList.filter(o => o.ITEM_CODE === item.itemCode)
  // console.log('\n', "-".repeat(50), item.itemCode, "itemOrderClasses")
  // console.table(itemOrderClasses)

  if (itemOrderClasses.length === 0) {
    return itemObj;
  }

  const optionClassList = []
  orderClassList.map((cls, i) => {
    const idx = itemOrderClasses.findIndex(o => o.ORDER_CLASS_CODE === cls.ORDER_CLASS_CODE)
    if (idx >= 0) {

      const optItems = orderList.filter(o => o.ORDER_CLASS_CODE === cls.ORDER_CLASS_CODE)
      const optItemList = optItems.map(item => {
        return {
          // ...item,
          optionClassCode: item.ORDER_CLASS_CODE,
          optionItemCode: item.ITEM_CODE,
          optionItemName: item.ITEM_NAME,
          optPrice: parseInt(item.ITEM_PRICE),
        }
      })

      optionClassList.push({
        // ...cls,
        optionClassCode: cls.ORDER_CLASS_CODE,
        optionClassName: cls.ORDER_CLASS_NAME,
        required: cls.ESSENTIAL_YN === "Y",
        maxQty: cls.MAX_SELECT_QTY,
        optionItemList: optItemList
      })
    }
    return null
  })

  // console.log('\n', "-".repeat(50), item.itemCode, "optionClassList")
  // console.table(optionClassList)
  // logOrderClassListEasypos(optionClassList)

  const optClsList = sortObjArray2({ arr: optionClassList, key: "required", type: "up" })
  itemObj.SetMenuItemList = optClsList
  itemObj.optionClassList = optClsList

  return itemObj;
}

function logOrderClassListEasypos(orderClassList) {
  const names = [
    "ORDER_CLASS_CODE",
    "ORDER_CLASS_NAME",
    "optionClassCode", "optionClassName",
    "ESSENTIAL_YN",
    "MAX_SELECT_QTY",
    "required",
    "maxQty"
  ]
  const shortArr = getShortArray(orderClassList, names)
  console.table(shortArr)
}


/** @optionClassList [{ optionClassCode, optionClassName, required, maxQty, optionItemList, ...rest}] */
/** @optionItemList [{ optionClassCode, optionItemCode, optionItemName, optPrice, ...rest}] */
function createItemObjDpos({ item, itemList, itemOptions2 }) {

  const itemObj = { item }

  /** @단품메뉴 */
  if (item.optionGroupCode === "") {
    return itemObj;
  }

  const optGroupList = itemOptions2.optionGroupList
  const optClassList = itemOptions2.optionClassList
  const optItemList = itemOptions2.optionItemList

  const grps = optGroupList.filter(g => g.optionGroupCode === item.optionGroupCode)
  if (grps.length < 1) return itemObj

  const clsCodes = grps[0].optionClassCodes

  const optionClassList = []

  clsCodes.map(code => {
    const classes = optClassList.filter(cls => cls.optionClassCode === code)

    if (classes.length === 1) {

      const optItems = optItemList.filter(item => classes[0].optionItemCodes.includes(item.optionItemCode))
      const optItems2 = optItems.map(item => {
        return {
          ...item,
          optionClassCode: classes[0].optionClassCode,
          optPrice: item.price,
        }
      })

      optionClassList.push({
        ...classes[0],
        maxQty: classes[0].maxQty || 1,
        optionItemList: optItems2
      })

    }

    return null
  })

  itemObj.optionClassList = optionClassList

  return itemObj
}
